import axiosInstance from "./api";
const token = localStorage.getItem("token");

export const loginUser = async (userData) => {
  const data = new FormData();
  data.append("email", userData.email);
  data.append("password", userData.password);

  const response = await axiosInstance.request({
    url: "login",
    method: "POST",
    header: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data,
  });

  return response;
};

export const addCategory = async (jobCategoryData) => {
  const data = new FormData();
  data.append("name", jobCategoryData.category);

  const response = await axiosInstance.request({
    url: "addjobCategory",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};


export const showCategory = async () => {
  const response = await axiosInstance.request({
    url: "showjobCategory",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const allJobs = async () => {
  const response = await axiosInstance.request({
    url: "Alljob",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const addJobDetail = async (jobDetailData) => {
  const data = new FormData();
  data.append("state", jobDetailData.state);
  data.append("designation", jobDetailData.designation);
  data.append("location", jobDetailData.location);
  data.append("category", jobDetailData.category);
  data.append("discription", jobDetailData.discription);

  const response = await axiosInstance.request({
    url: "addjob",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const addjobdescription = async (jobDetailData) => {
  const data = new FormData();
  data.append("discription", jobDetailData.discription);
  data.append("meta_title", jobDetailData.metaTitle);
  data.append("meta_description", jobDetailData.metaDescription);
  data.append("meta_tag", jobDetailData.metaTag);
  const response = await axiosInstance.request({
    url: "add_job_page_front",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const Jobdescription = async () => {
  const response = await axiosInstance.request({
    url: "list_job_page_front",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const allNewsLetter = async () => {
  const response = await axiosInstance.request({
    url: "shownewslatter",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};