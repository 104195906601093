import React, { useState } from "react";
import IMAGES from "../../assets/images";
import styles from "../../admin.module.css";
import { Link, useLocation } from "react-router-dom";
import { downArrow } from "../../../utils/svg.file";
const { whiteLogo } = IMAGES;
export default function Sidebar() {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openEnquiryDropdown, setOpenEnquiryDropdown] = useState(false);
  const [openContentDropdown, setOpenContentDropdown] = useState(false);
  const [openCelebrationDropdown, setOpenCelebrationDropdown] = useState(false);
  const [openGiftCardDropdown, setOpenGiftCardDropdown] = useState(false);
  const [travelDropdown, setTravelDropdown] = useState(false);
  const [restaurantDropdown, setRestaurantDropdown] = useState(false);
  const [retreatsDropdown, setRetreatsDropdown] = useState(false);
  const [cruiseDropdown, setCruiseDropdown] = useState(false);
  const [openExclusiveDropdown, setOpenExclusiveDropdown] = useState(false);
  const [openExperienceDropdown, setOpenExperienceDropdown] = useState(false);
  const [openTicketDropdown, setOpenTicketDropdown] = useState(false);
  const [openSeoDropdown, setOpenSeoDropdown] = useState(false);
  const location = useLocation();
  return (
    <>
      <div className={styles.sidebar_div}>
        <div className={styles.sidebar_logo}>
          <img src={whiteLogo} alt="" />
        </div>
        <div className={styles.side_menu_list}>
          <ul className={styles.main_list_sidebar}>
            <li
              className={
                location.pathname === "/dashboard" ? [styles.active] : ""
              }
            >
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li
              className="cursor-btn d-flex justify-content-between"
              onClick={() => {
                setRetreatsDropdown(!retreatsDropdown ? true : false);
                setTravelDropdown(false);
                setOpenCelebrationDropdown(false);
                setOpenEnquiryDropdown(false);
                setOpenDropdown(false);
                setOpenContentDropdown(false);
                setRestaurantDropdown(false);
              }}
            >
              <Link to="#">Retreats</Link>
              <span>{downArrow}</span>
            </li>
            {retreatsDropdown ? (
              <div className={styles.job_dropdown_sidemenu}>
                <ul className="m-0">
                  {/* <li
                    className={
                      location.pathname === "/manage-retreats-category" ||
                      location.pathname === "/add-retreats-category" ||
                      location.pathname === "/edit-retreats-category"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-retreats-category">
                      Manage Retreats Name
                    </Link>{" "}
                  </li> */}
                  <li
                    className={
                      location.pathname ===
                        "/manage-retreats-landing-page-banner" ||
                      location.pathname ===
                        "/edit-retreats-landing-page-banner" ||
                      location.pathname === "/add-retreats-landing-page-banner"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-retreats-landing-page-banner">
                      Landing page
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/manage-retreats" ||
                      location.pathname === "/edit-retreats" ||
                      location.pathname === "/add-retreats"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-retreats">Retreats content </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/manage-room" ||
                      location.pathname === "/edit-room" ||
                      location.pathname === "/add-room"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-room"> Room</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/manage-getting-here" ||
                      location.pathname === "/edit-getting-here"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-getting-here">Manage Getting Here</Link>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
            <li
              className="cursor-btn d-flex justify-content-between"
              onClick={() => {
                setRestaurantDropdown(!restaurantDropdown ? true : false);
                setTravelDropdown(false);
                setOpenCelebrationDropdown(false);
                setOpenEnquiryDropdown(false);
                setOpenDropdown(false);
                setOpenContentDropdown(false);
                setRetreatsDropdown(false);
              }}
            >
              <Link to="#">Restaurants</Link>
              <span>{downArrow}</span>
            </li>
            {restaurantDropdown ? (
              <div className={styles.job_dropdown_sidemenu}>
                <ul className="m-0">
                  {/* <li>
                    <Link to="/manage-restaurant">Manage Restaurant</Link>
                  </li> */}
                  <li
                    className={
                      location.pathname ===
                        "/manage-restaurant-landing-page-banner" ||
                      location.pathname ===
                        "/edit-restaurant-landing-page-banner" ||
                      location.pathname ===
                        "/add-restaurant-landing-page-banner"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-restaurant-landing-page-banner">
                      Landing Page
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname ===
                        "/manage-restaurant-package-and-detail" ||
                      location.pathname ===
                        "/edit-restaurant-package-and-detail" ||
                      location.pathname === "/add-restaurant-package-and-detail"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-restaurant-package-and-detail">
                      Restaurants content
                    </Link>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
            <li
              className="cursor-btn d-flex justify-content-between"
              onClick={() => {
                setOpenExperienceDropdown(
                  !openExperienceDropdown ? true : false
                );
                setOpenGiftCardDropdown(false);
                setOpenEnquiryDropdown(false);
                setOpenDropdown(false);
                setOpenContentDropdown(false);
                setOpenCelebrationDropdown(false);
                setRetreatsDropdown(false);
                setRestaurantDropdown(false);
              }}
            >
              <Link to="#">Stories</Link>
              <span>{downArrow}</span>
            </li>
            {openExperienceDropdown ? (
              <div className={styles.job_dropdown_sidemenu}>
                <ul className="m-0">
                  <li
                    className={
                      location.pathname === "/manage-experience-landing-page" ||
                      location.pathname === "/edit-experience-landing-page" ||
                      location.pathname === "/add-experience-landing-page"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-experience-landing-page">
                      Landing Page
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/add-experience-category" ||
                      location.pathname === "/edit-experience-category" ||
                      location.pathname === "/manage-experience-category"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-experience-category">
                      Stories category{" "}
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/manage-experience" ||
                      location.pathname === "/edit-experience" ||
                      location.pathname === "/add-experience"
                        ? [styles.active]
                        : ""
                    }
                  >
                    {" "}
                    <Link to="/manage-experience">Stories content </Link>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
            <li
              className="cursor-btn d-flex justify-content-between"
              onClick={() => {
                setTravelDropdown(!travelDropdown ? true : false);
                setOpenCelebrationDropdown(false);
                setOpenEnquiryDropdown(false);
                setOpenDropdown(false);
                setOpenContentDropdown(false);
                setRestaurantDropdown(false);
                setRetreatsDropdown(false);
              }}
            >
              <Link to="#">Travels</Link>
              <span>{downArrow}</span>
            </li>
            {travelDropdown ? (
              <div className={styles.job_dropdown_sidemenu}>
                <ul className="m-0">
                  <li
                    className={
                      location.pathname ===
                        "/manage-travel-landing-page-banner" ||
                      location.pathname ===
                        "/edit-travel-landing-page-banner" ||
                      location.pathname === "/add-travel-landing-page-banner"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-travel-landing-page-banner">
                      Landing Page
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/manage-travel-category" ||
                      location.pathname === "/edit-travel-category" ||
                      location.pathname === "/add-travel-category"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-travel-category">Travel Category</Link>
                  </li>
                  <li
                    className={
                      location.pathname ===
                        "/manage-travel-package-and-detail" ||
                      location.pathname === "/edit-travel-package-and-detail" ||
                      location.pathname === "/add-travel-package-and-detail"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-travel-package-and-detail">
                      Travel Packages
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/manage-celebration-event-and-block">
                      Manage Event & Block
                    </Link>
                  </li> */}
                </ul>
              </div>
            ) : (
              ""
            )}
            <li
              className="cursor-btn d-flex justify-content-between"
              onClick={() => {
                setCruiseDropdown(!cruiseDropdown ? true : false);
                setRetreatsDropdown(false);
                setTravelDropdown(false);
                setOpenCelebrationDropdown(false);
                setOpenEnquiryDropdown(false);
                setOpenDropdown(false);
                setOpenContentDropdown(false);
                setRestaurantDropdown(false);
              }}
            >
              <Link to="#">Cruises</Link>
              <span>{downArrow}</span>
            </li>
            {cruiseDropdown ? (
              <div className={styles.job_dropdown_sidemenu}>
                <ul className="m-0">
                  <li
                    className={
                      location.pathname === "/manage-cruise-landing-page" ||
                      location.pathname === "/edit-cruise-landing-page" ||
                      location.pathname === "/add-cruise-landing-page"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-cruise-landing-page">Landing Page</Link>
                  </li>
                  <li
                    className={
                      location.pathname ===
                        "/manage-cruise-package-and-detail" ||
                      location.pathname === "/edit-cruise-package-and-detail" ||
                      location.pathname === "/add-cruise-package-and-detail"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-cruise-package-and-detail">
                      Cruises Category
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/manage-cruise-enquiry-section" ||
                      location.pathname === "/edit-cruise-enquiry-section" ||
                      location.pathname === "/add-cruise-enquiry-section"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-cruise-enquiry-section">
                      Cruises Packages
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/cruise-enquiry-list"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/cruise-enquiry-list">Cruises Enquiry List</Link>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
            <li
              className={
                location.pathname === "/manage-featured-story" ||
                location.pathname === "/add-featured-story" ||
                location.pathname === "/edit-featured-story"
                  ? [styles.active]
                  : ""
              }
            >
              <Link to="/manage-featured-story">Featured Story</Link>
            </li>
            <li
              className="cursor-btn d-flex justify-content-between"
              onClick={() => {
                setOpenCelebrationDropdown(
                  !openCelebrationDropdown ? true : false
                );
                setOpenEnquiryDropdown(false);
                setOpenDropdown(false);
                setOpenContentDropdown(false);
                setRestaurantDropdown(false);
                setRetreatsDropdown(false);
              }}
            >
              <Link to="#">Celebration & Event</Link>
              <span>{downArrow}</span>
            </li>
            {openCelebrationDropdown ? (
              <div className={styles.job_dropdown_sidemenu}>
                <ul className="m-0">
                  <li
                    className={
                      location.pathname ===
                        "/manage-celebration-landing-page-banner" ||
                      location.pathname ===
                        "/edit-celebration-landing-page-banner" ||
                      location.pathname ===
                        "/add-celebration-landing-page-banner"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-celebration-landing-page-banner">
                      Landing Page
                    </Link>
                  </li>

                  <li
                    className={
                      location.pathname ===
                        "/manage-celebration-landing-page-content" ||
                      location.pathname ===
                        "/edit-celebration-landing-page-content" ||
                      location.pathname ===
                        "/add-celebration-landing-page-content"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-celebration-landing-page-content">
                      Landing Page Content
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname ===
                        "/manage-celebration-banner-content" ||
                      location.pathname ===
                        "/edit-celebration-banner-content" ||
                      location.pathname === "/add-celebration-banner-content"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-celebration-banner-content">
                      Banner & Content
                    </Link>
                  </li>

                  <li
                    className={
                      location.pathname ===
                        "/manage-celebration-event-and-block" ||
                      location.pathname ===
                        "/add-celebration-event-and-block" ||
                      location.pathname === "/edit-celebration-event-and-block"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-celebration-event-and-block">
                      Event & Block
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/celebration-enquiry-list"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/celebration-enquiry-list">
                      Celebration Enquiry
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/manage-celebration-block"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-celebration-block">Manage Block</Link>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
            <li
              className="cursor-btn d-flex justify-content-between"
              onClick={() => {
                setOpenGiftCardDropdown(!openGiftCardDropdown ? true : false);
                setOpenEnquiryDropdown(false);
                setOpenDropdown(false);
                setOpenContentDropdown(false);
                setOpenCelebrationDropdown(false);
                setRetreatsDropdown(false);
                setRestaurantDropdown(false);
              }}
            >
              <Link to="#">Gift Card</Link>
              <span>{downArrow}</span>
            </li>
            {openGiftCardDropdown ? (
              <div className={styles.job_dropdown_sidemenu}>
                <ul className="m-0">
                  <li
                    className={
                      location.pathname === "/manage-category" ||
                      location.pathname === "/edit-job-category" ||
                      location.pathname === "/add-job-category"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-gift-card">Manage GiftCard</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/manage-category" ||
                      location.pathname === "/edit-job-category" ||
                      location.pathname === "/add-job-category"
                        ? [styles.active]
                        : ""
                    }
                  >
                    {" "}
                    <Link to="/gift-applier-list">Applier Record</Link>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}

            <li
              className="cursor-btn d-flex justify-content-between"
              onClick={() => {
                setOpenExclusiveDropdown(!openExclusiveDropdown ? true : false);
                setOpenGiftCardDropdown(false);
                setOpenEnquiryDropdown(false);
                setOpenDropdown(false);
                setOpenContentDropdown(false);
                setOpenCelebrationDropdown(false);
                setRetreatsDropdown(false);
                setRestaurantDropdown(false);
              }}
            >
              <Link to="#"> Offers</Link>
              <span>{downArrow}</span>
            </li>
            {openExclusiveDropdown ? (
              <div className={styles.job_dropdown_sidemenu}>
                <ul className="m-0">
                  <li
                    className={
                      location.pathname ===
                        "/manage-exclusive-offer-category" ||
                      location.pathname === "/add-exclusive-offer-category" ||
                      location.pathname === "/edit-exclusive-offer-category"
                        ? [styles.active]
                        : ""
                    }
                  >
                    {" "}
                    <Link to="/manage-exclusive-offer-category">
                      Offers Category
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/manage-exclusive-offer" ||
                      location.pathname === "/add-exclusive-offer" ||
                      location.pathname === "/edit-exclusive-offer"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-exclusive-offer">Manage Offer</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/manage-offer-seo" ||
                      location.pathname === "/add-offer-seo" ||
                      location.pathname === "/edit-offer-seo"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-offer-seo">Manage Offer Seo</Link>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}

            <li
              className="d-flex justify-content-between cursor-btn"
              onClick={() => {
                setOpenDropdown(!openDropdown ? true : false);
                setOpenEnquiryDropdown(false);
                setOpenContentDropdown(false);
                setOpenCelebrationDropdown(false);
                setRetreatsDropdown(false);
              }}
            >
              <Link to="#">Job Opportunities</Link>
              <span>{downArrow}</span>
            </li>
            {openDropdown ? (
              <div className={styles.job_dropdown_sidemenu}>
                <ul className="m-0">
                  <li
                    className={
                      location.pathname === "/manage-job" ||
                      location.pathname === "/edit-job-detail" ||
                      location.pathname === "/add-job-detail"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-job">Job Intro</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/manage-job-description" ||
                      location.pathname === "/edit-job-description" ||
                      location.pathname === "/add-job-description"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-job-description">Job Descriptions</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/manage-category" ||
                      location.pathname === "/edit-job-category" ||
                      location.pathname === "/add-job-category"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-category">Job Category</Link>
                  </li>

                  <li
                    className={
                      location.pathname === "/manage-job-applier" ||
                      location.pathname === "/edit-job-applier" ||
                      location.pathname === "/add-job-applier"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-job-applier">Manage Job Application</Link>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}

            <li
              className="cursor-btn d-flex justify-content-between"
              onClick={() => {
                setOpenContentDropdown(!openContentDropdown ? true : false);
                setOpenEnquiryDropdown(false);
                setOpenDropdown(false);
                setOpenCelebrationDropdown(false);
                setRestaurantDropdown(false);
                setRetreatsDropdown(false);
              }}
            >
              <Link to="#">Company Information</Link>
              <span>{downArrow}</span>
            </li>
            {openContentDropdown ? (
              <div className={styles.job_dropdown_sidemenu}>
                <ul className="m-0">
                  <li
                    className={
                      location.pathname === "/manage-about-us" ||
                      location.pathname === "/edit-about-us" ||
                      location.pathname === "/add-about-us"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="manage-about-us">About us</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/manage-accolades" ||
                      location.pathname === "/edit-accolades" ||
                      location.pathname === "/add-accolades"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-accolades">Accolades</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/manage-contact-us" ||
                      location.pathname === "/edit-contact-us" ||
                      location.pathname === "/add-contact-us"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-contact-us">Contact us</Link>
                  </li>
                  {/* <li>
                    <Link to="#">New Development</Link>
                  </li> */}

                  <li
                    className={
                      location.pathname === "/manage-privacy-notice" ||
                      location.pathname === "/edit-privacy-notice" ||
                      location.pathname === "/add-privacy-notice"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-privacy-notice">Privacy Notice</Link>
                  </li>
                  {/* <li>
                    <Link to="#">Sustainability</Link>
                  </li> */}
                </ul>
              </div>
            ) : (
              ""
            )}
            {/* <li
              className="cursor-btn d-flex justify-content-between"
              onClick={() => {
                setOpenEnquiryDropdown(!openEnquiryDropdown ? true : false);
                setOpenDropdown(false);
                setOpenContentDropdown(false);
                setOpenCelebrationDropdown(false);
                setRetreatsDropdown(false);
              }}
            >
              <Link to="#">Enquiry List</Link> <span>{downArrow}</span>
            </li>
            {openEnquiryDropdown ? (
              <div className={styles.job_dropdown_sidemenu}>
                <ul className="m-0">
                  <li
                    className={
                      location.pathname === "/cruise-enquiry-list"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/cruise-enquiry-list">
                      Cruise Enquiry List
                    </Link>
                  </li>
                   <li>
                    <Link to="/taste-enquiry-list">
                      Taste Enquiry List
                    </Link>
                  </li>
                  <li>
                    <Link to="/celebration-enquiry-list">
                      Celebration Enquiry List
                    </Link>
                  </li>
                  <li>
                    <Link to="/stay-enquiry-list">Stay Enquiry List</Link>
                  </li>
                  <li>
                    <Link to="/travel-enquiry-list">
                      Travel Enquiry List
                    </Link>
                  </li> 
                </ul>
              </div>
            ) : (
              ""
            )} */}
            <li
              className="cursor-btn d-flex justify-content-between"
              onClick={() => {
                setOpenTicketDropdown(!openTicketDropdown ? true : false);
                setOpenDropdown(false);
                setOpenContentDropdown(false);
                setOpenCelebrationDropdown(false);
                setRetreatsDropdown(false);
              }}
            >
              <Link to="#">Ticket</Link> <span>{downArrow}</span>
            </li>
            {openTicketDropdown ? (
              <div className={styles.job_dropdown_sidemenu}>
                <ul className="m-0">
                  <li
                    className={
                      location.pathname === "/manage-payment-history" ||
                      location.pathname === "/order-detail"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-payment-history">
                      Manage Payment History
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/manage-ticket" ||
                      location.pathname === "/add-ticket" ||
                      location.pathname === "/edit-ticket"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-ticket">Manage Ticket</Link>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}

            <li
              className="cursor-btn d-flex justify-content-between"
              onClick={() => {
                setOpenSeoDropdown(!openContentDropdown ? true : false);
                setOpenEnquiryDropdown(false);
                setOpenDropdown(false);
                setOpenCelebrationDropdown(false);
                setRestaurantDropdown(false);
                setRetreatsDropdown(false);
                setOpenContentDropdown(false);
              }}
            >
              <Link to="#">Manage Seo</Link>
              <span>{downArrow}</span>
            </li>
            {openSeoDropdown ? (
              <div className={styles.job_dropdown_sidemenu}>
                <ul className="m-0">
                  <li
                    className={
                      location.pathname === "/manage-accolades-seo" ||
                      location.pathname === "/edit-accolades-seo" ||
                      location.pathname === "/add-accolades-seo"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-accolades-seo">Accolades</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/manage-contact-us-seo" ||
                      location.pathname === "/edit-contact-us-seo" ||
                      location.pathname === "/add-contact-us-seo"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="/manage-contact-us-seo">Contact us</Link>
                  </li>
                  {/* <li>
                    <Link to="#">New Development</Link>
                  </li> */}
                  <li
                    className={
                      location.pathname === "/manage-sustainability-seo" ||
                      location.pathname === "/edit-sustainability-seo" ||
                      location.pathname === "/add-sustainability-seo"
                        ? [styles.active]
                        : ""
                    }
                  >
                    <Link to="manage-sustainability-seo">Sustainability</Link>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
            <li
              className={
                location.pathname === "/manage-news-letter"
                  ? [styles.active]
                  : ""
              }
            >
              <Link to="/manage-news-letter"> Newsletter</Link>
            </li>
            <li
              className={
                location.pathname === "/manage-admin-settings"
                  ? [styles.active]
                  : ""
              }
            >
              <Link to="/manage-admin-settings">Admin Settings</Link>
            </li>
            <li
              className={
                location.pathname === "/manage-sidemenu" ? [styles.active] : ""
              }
            >
              <Link to="/manage-sidemenu">Side Menu</Link>
            </li>
            <li
              className={
                location.pathname === "/manage-homepage" ||
                location.pathname === "/add-homepage" ||
                location.pathname === "/edit-homepage"
                  ? [styles.active]
                  : ""
              }
            >
              <Link to="/manage-homepage">Homepage</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
