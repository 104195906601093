import React, { useEffect, useState } from "react";
import styles from "./exclusiveOffer.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Base_URL } from "../../../serverUrl";
import swal from "sweetalert";
import { addExclusiveOffer, showExclusiveCategoryList, showRestaurantList, showRetreatList } from "../../../api/adminPanel/exclusiveOfferApi";
import ReactQuill from "react-quill";
// import { Upload } from "";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { showRetreatListArray } from "../../../api/adminPanel/giftCardApi";
import { UploadIcon } from "../../../utils/svg.file";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { Select } from 'antd';
import Checkbox from "@mui/material/Checkbox";


export default function AddExclusiveOffer() {
  const [file, setFile] = useState("");
  const [imgSmall, setImgSmall] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [packageDescription, setPackageDescription] = useState("");
  const [termCondition, setTermCondition] = useState("");
  const [category, setCategory] = useState("");
  const [country, setCountry] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [duration, setDuration] = useState("");
  const [enquiryLink, setEnquiryLink] = useState("");
  const [name, setName] = useState("");
  const [imageBanner, setImageBanner] = useState("");
  const [offersValue, setOffersValue] = useState("");
  const [offerType, setOfferType] = useState("restaurants");
  const [retreatList, setRetreatList] = useState([]);
  const [retraurantList, setRetraurantList] = useState([]);
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTag, setMetaTag] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [finalList, setFinalList] = useState([]);
    const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }
// const handleChange = (value) => {
// };

  const navigate = useNavigate();
  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };

  const [fileList, setFileList] = useState([]);
  const [bannerList, setBannerList] = useState([]);

  const onChange = ({ fileList }) => {
    setFileList(fileList);
    setImgSmall(fileList[0].originFileObj);
  };
  const BannerOnChange = async ({ fileList }) => {
    // setFileList(fileList);
    setImageBanner(fileList[0].originFileObj);
  };
  const onChangeBanner = ({ bannerList }) => {
    setBannerList(bannerList);

    // setImgBanner(bannerList[0].originFileObj);
  };

  const onPreviewSmall = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };
  const [exclusiveOfferList, setExclusiveOfferList] = useState([]);
  const handleGetExclusiveCategoryList = async (id) => {
    const response = await showExclusiveCategoryList(id);
    if (response.remote === "success") {
      setExclusiveOfferList(response.data.data);
    } else {
    }
  };
  const handleExclusiveOffer = async () => {
    const addExclusiveOfferPayload = {
      name: name,
      category: category,
      subcategory: subCategory,
      title: name,
      shortDescription: shortDescription,
      packageDescription: packageDescription,
      terms: termCondition,
      image: imgSmall,
      bannerImage: imageBanner,
      country: country,
      package_duration: duration,
      locationId: offersValue,
      offerType: offerType,
      listData: listData,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
      metaTag: metaTag,
      enquiryLink: enquiryLink,
    };
    const response = await addExclusiveOffer(addExclusiveOfferPayload);

    if (response.remote === "success") {
      if(response.data.errors){

        swal("Offer Name Already Exist");
      }else{
        swal("Offers Successfully Added");

        navigate("/manage-exclusive-offer");
        setName("");
        setCategory("");
        setSubCategory("");
        setCountry("");
        setTermCondition("");
        // setImgBanner("");
        setImgSmall("");
        setShortDescription("");
        setPackageDescription("");
        setDuration("");
      }
    } else {
    }
  };
  const handleRetraurantList = async () => {
    const response = await showRestaurantList();

    if (response.remote === "success") {
      
      setRetraurantList(response.data.data);
      setFinalList(response.data.data);
    } else {
    }
  };
  const handleRetreatList = async () => {
    const response = await showRetreatListArray();

    if (response.remote === "success") {
      setRetreatList(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleRetraurantList();
    handleRetreatList();
    handleGetExclusiveCategoryList();
  }, []);
  const handlechangeValue = (value) => {
    setOfferType(value);
    if (value === "retreats") {
      setFinalList(retreatList);
      setListData([])
    } else {
      setFinalList(retraurantList);
      setListData([])

    }
  };
  const [listData, setListData] = React.useState([]);

  const handleChange = (vl,label) => {
    
   setListData(vl)
  };

  const pagesList = [
    {
      value: "1",
      label: "Retreats",
    },
    {
      value: "2",
      label: "Restaurants",
    },
    {
      value: "3",
      label: "Cruises",
    },
    {
      value: "4",

      label: "Travel",
    },
    {
      value: "5",

      label: "Homepage",
    },
  ];

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Offers</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">Country</label>
                  <Form.Select
                    onChange={(e) => setCountry(e.target.value)}
                    aria-label="Default select example"
                    className="mb-4"
                  >
                    <option selected disabled>
                      Select Country
                    </option>
                    <option value="malaysia">Malaysia</option>
                    <option value="singapore">Singapore</option>
                  </Form.Select>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Category</label>

                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option selected disabled>
                      Select Category
                    </option>
                    {exclusiveOfferList?.map((item) => (
                      <option value={item.id}>{item.title}</option>
                    ))}
                    {/* <option value="travel">Travel</option>
                    <option value="celebration">Celebration</option>
                    <option value="retreats">Retreats</option>
                    <option value="cruise">Cruise</option> */}
                  </Form.Select>
                </Col>

                <Col md={6}>
                  <div className="d-flex">
                    <div className="">
                      <label className="fw-bold">
                        Preview Image{" "}
                        <small className="text-success small-msg">
                          (Max. Size 532 X 662 px)
                        </small>
                      </label>
                      <ImgCrop grid aspect={532 / 662}>
                        <Upload
                          action={Base_URL}
                          listType="picture-card"
                          fileList={fileList}
                          onChange={onChange}
                          onPreview={onPreviewSmall}
                        >
                          {fileList.length < 1 && (
                            <>
                              <div className="file_upload_div">
                                <span>{UploadIcon}</span>
                                <p>Click Here to Upload</p>
                              </div>
                            </>
                          )}
                        </Upload>
                      </ImgCrop>
                      <label className="fw-bold">
                        Banner Image{" "}
                        <small className="text-success small-msg">
                          (Max. Size 731 X 441 px)
                        </small>
                      </label>
                      <ImgCrop grid aspect={731 / 441}>
                        <Upload
                          maxCount={1}
                          onChange={BannerOnChange}
                          action={Base_URL}
                          listType="picture-card"
                          defaultFileList={[...fileList]}
                        >
                          {imageBanner.length < 1 && (
                            <>
                              <div className="file_upload_div">
                                <span>{UploadIcon}</span>
                                <p>Click Here to Upload</p>
                              </div>
                            </>
                          )}
                        </Upload>
                      </ImgCrop>
                    </div>
                    {/* <div className="">
                      {" "}
                      <label className="fw-bold">Banner Image</label>
                      <ImgCrop grid aspect={3 / 2}>
                        <Upload
                          action={Base_URL}
                          listType="picture-card"
                          fileList={fileList}
                          onChange={onChangeBanner}
                          onPreview={onPreviewSmall}
                        >
                          {bannerList?.length < 1 && "+ Upload"}
                        </Upload>
                      </ImgCrop>
                    </div> */}
                  </div>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Name</label>

                  <Form.Control
                    type="text"
                    placeholder="Name"
                    className={styles.form_input}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label className="fw-bold">Sub Category</label>

                  <Form.Control
                    type="text"
                    placeholder="Sub Category"
                    className={styles.form_input}
                    onChange={(e) => setSubCategory(e.target.value)}
                  />
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="restaurants"
                      control={<Radio />}
                      label="Restaurant"
                      onChange={(e) => handlechangeValue(e.target.value)}
                    />
                    <FormControlLabel
                      value="retreats"
                      control={<Radio />}
                      label="Retreats"
                      onChange={(e) => handlechangeValue(e.target.value)}
                    />
                  </RadioGroup>
                  {/* <label className="fw-bold">
                    Select Restaurant / Retreats
                  </label>
                              <Select
                mode="tags"
                style={{
                  width: '100%',
                }}
                value={listData}
                onChange={(value,label)=>handleChange2(value,label)}
                tokenSeparators={[',']}
                options={finalList}
              /> */}
                  <Form.Select
                    className={styles.form_input}
                    onChange={(e) => setOffersValue(e.target.value)}
                  >
                    <option selected>Select Restaurant / Retreats</option>

                    {finalList?.map((item, index) => {
                      return (
                        <>
                          <option value={item.value}>
                            {item.label}, {item.other}
                          </option>
                        </>
                      );
                    })}
                  </Form.Select>
                  {/* <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Tag
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={personName}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={personName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                </Col>

                <Col md={6}>
                  <label className="fw-bold">Short Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Short Description"
                    className={styles.form_input}
                    onChange={(e) => setShortDescription(e.target.value)}
                  />
                </Col>

                <Col md={6} className="">
                  <label className="fw-bold">Term & Condition</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Term & Condition"
                    className={styles.form_input}
                    onChange={(e) => setTermCondition(e.target.value)}
                  />
                  {/* <ReactQuill
                    theme="snow"
                    placeholder="Term & Condition"
                    value={termCondition}
                    onChange={setTermCondition}
                  /> */}
                </Col>
                <Col md={12}>
                  <label className="fw-bold">Package Duration</label>

                  <Form.Control
                    type="text"
                    placeholder="Package Duration"
                    className={styles.form_input}
                    onChange={(e) => setDuration(e.target.value)}
                  />
                </Col>
                <Col md={12} className="mb-3">
                  <label className="fw-bold">Package Description</label>

                  <ReactQuill
                    theme="snow"
                    placeholder="Package Description"
                    value={packageDescription}
                    onChange={setPackageDescription}
                  />
                </Col>
                <Col md={12} className="mb-2">
                  <label className="fw-bold">Select Pages</label>

                  <Select
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    placeholder=""
                    onChange={handleChange}
                    options={pagesList}
                  />
                </Col>
                <Col md={12} className="mb-2">
                  <label className="fw-bold">Enquiry Link</label>

                  <Form.Control
                    type="text"
                    placeholder="Enquiry Link"
                    className={styles.form_input}
                    onChange={(e) =>setEnquiryLink(e.target.value)}
                  />
                </Col>
                <label className="fw-bold">Enter Meta</label>
                <Col md={12}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Meta Title"
                    className={styles.form_input}
                    onChange={(e) => setMetaTitle(e.target.value)}
                  />
                </Col>
                <Col md={12}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Meta Description"
                    className={styles.form_input}
                    onChange={(e) => setMetaDescription(e.target.value)}
                  />
                  <Form.Control
                    type="text"
                    placeholder="Meta Tag"
                    className={styles.form_input}
                    onChange={(e) => setMetaTag(e.target.value)}
                  />
                </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={() => handleExclusiveOffer()}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
      {/* <CropperModal
        file={file}
        showCropModal={showCropModal}
        hideCropModal={hideCropModal}
        handleShowCropModal={handleShowCropModal}
      /> */}
    </>
  );
}
