import React, { useCallback, useEffect, useState } from "react";
import styles from "./exclusiveOffer.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { Select, Upload } from "antd";
import { Base_URL, Featured_URL, Offers_URL } from "../../../serverUrl";
import swal from "sweetalert";
import {
  editExclusiveOfferApi,
  showExclusiveCategoryList,
  showRestaurantList,
  showRetreatList,
  updateExclusiveOffer,
} from "../../../api/adminPanel/exclusiveOfferApi";
import ReactQuill from "react-quill";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { showRetreatListArray } from "../../../api/adminPanel/giftCardApi";
import { UploadIcon } from "../../../utils/svg.file";
export default function EditExclusiveOffer() {
  const [imgHome, setImgBanner] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [packageDescription, setPackageDescription] = useState("");
  const [termCondition, setTermCondition] = useState("");
  const [category, setCategory] = useState("");
  const [country, setCountry] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [duration, setDuration] = useState("");
  const [imageBanner, setImageBanner] = useState("");
  const [offersValue, setOffersValue] = useState("");
  const [offerType, setOfferType] = useState("");
  const [retreatList, setRetreatList] = useState([]);
  const [retraurantList, setRetraurantList] = useState([]);
  const [finalList, setFinalList] = useState([]);
  const [name, setName] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTag, setMetaTag] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [enquiryLink, setEnquiryLink] = useState("");

  const navigate = useNavigate();
  let { id } = useParams();

  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };
  const [fileList, setFileList] = useState([]);

  const onChange = ({ fileList }) => {
    setFileList(fileList);
    setImgBanner(fileList[0].originFileObj);
  };
  const BannerOnChange = async ({ fileList }) => {
    //  setFileList(fileList);
    setImageBanner(fileList[0].originFileObj);
  };

  const [exclusiveOfferList, setExclusiveOfferList] = useState([]);
  const handleGetExclusiveCategoryList = async (id) => {
    const response = await showExclusiveCategoryList(id);
    if (response.remote === "success") {
      setExclusiveOfferList(response.data.data);
    } else {
    }
  };
  const handleGetExclusiveOfferDetail = async (id) => {
    const response = await editExclusiveOfferApi(id);

    if (response.remote === "success") {
      setName(response.data.data.title);
      setCategory(response.data.data.category);
      setSubCategory(response.data.data.subcategory);
      setCountry(response.data.data.country);
      setTermCondition(response.data.data.terms);
      setImgBanner(response.data.data.image);
      setImageBanner(response.data.data.bannerImage);
      setShortDescription(response.data.data.shortDescription);
      setPackageDescription(response.data.data.packageDescription);
      setDuration(response.data.data.package_duration);
      setOfferType(response.data.data.offerType);
      setOffersValue(response.data.data.locationId);
      setEnquiryLink(response.data.data.enquiry_link);
      
  setMetaTitle(response.data.data.meta_title);
  setMetaDescription(response.data.data.meta_description);
  setMetaTag(response.data.data.meta_tag);
      setListData(JSON.parse(response.data.retreat[0].locationId));
    } else {
    }
  };

  const updateExclusiveOfferDetail = async (id) => {
    const updateExclusiveOfferPayload = {
      category: category,
      subcategory: subCategory,
      title: name,
      shortDescription: shortDescription,
      packageDescription: packageDescription,
      terms: termCondition,
      image: imgHome,
      country: country,
      package_duration: duration,
      locationId: offersValue,
      offerType: offerType,
      bannerImage: imageBanner,
      listData: listData,
      metaTitle: metaTitle,
      metaTag: metaTag,
      metaDescription: metaDescription,
      enquiryLink: enquiryLink,
    };
    const response = await updateExclusiveOffer(
      updateExclusiveOfferPayload,
      id
    );

    if (response.remote === "success") {
      if(response.data.errors){

        swal("Offer Name Already Exist");
      }else{
      swal("Offers Successfully Update");
      navigate("/manage-exclusive-offer");
      }
    } else {
    }
  };


  useEffect(() => {
    handleGetExclusiveOfferDetail(id);
  }, [id]);
  const handleRetraurantList = async () => {
    const response = await showRestaurantList();

    if (response.remote === "success") {
      setRetraurantList(response.data.data);
      setFinalList(response.data.data);
    } else {
    }
  };
  const handleRetreatList = async () => {
    const response = await showRetreatListArray();

    if (response.remote === "success") {
      setRetreatList(response.data.data);
    } else {
    }
  };

  const handlechangeValue = useCallback((value) => {
    setOfferType(value);
    if (value === "retreats") {
      setFinalList(retreatList);
      // setListData([]);
    } else {
      setFinalList(retraurantList);
      // setListData([]);
    }
  }, [retraurantList, retreatList]);

  useEffect(() => {
    if(offerType) {

      handlechangeValue(offerType)
    }

  }, [handlechangeValue, offerType])

  useEffect(() => {
    if(offerType === 'retreats') {
     
      handleRetreatList();
    } else if (offerType === 'restaurants') {

      handleRetraurantList();
    }
    handleGetExclusiveCategoryList();
  }, [ offerType]);
 
  // useEffect(() => {
  //   if (offerType === "retreats") {
  //     setFinalList(retreatList);
  //     setListData([]);
  //   } else {
  //     setFinalList(retraurantList);
  //     setListData([]);
  //   }
  // }, [offerType, retraurantList, retreatList]);
  // const [listData, setListData] = React.useState([]);
  // const handleChange2 = (vl, label) => {
  //   setListData(label);
  // };

  const pagesList = [
    {
      value: "1",
      label: "Retreats",
    },
    {
      value: "2",
      label: "Restaurants",
    },
    {
      value: "3",
      label: "Cruises",
    },
    {
      value: "4",

      label: "Travel",
    },
    {
      value: "5",

      label: "Homepage",
    },
  ];
  const [listData, setListData] = React.useState([]);
  const handleChange = (value) => {

    setListData(value)
  };
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Edit Offers</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">Country</label>
                  <Form.Select
                    onChange={(e) => setCountry(e.target.value)}
                    aria-label="Default select example"
                    className="mb-4"
                    defaultValue={country}
                  >
                    <option selected disabled>
                      Select Country
                    </option>
                    <option
                      value="malaysia"
                      selected={country === "malaysia" ? true : false}
                    >
                      Malaysia
                    </option>
                    <option
                      value="singapore"
                      selected={country === "singapore" ? true : false}
                    >
                      Singapore
                    </option>
                  </Form.Select>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Category</label>

                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => setCategory(e.target.value)}
                    value={category}
                  >
                    {exclusiveOfferList?.map((item) => (
                      <option value={item.id}>{item.title}</option>
                    ))}
                  </Form.Select>
                </Col>

                <Col md={6}>
                  <label className="fw-bold">
                    Preview Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 532 X 662 px)
                    </small>
                  </label>

                  <ImgCrop grid aspect={532 / 662}>
                    <Upload
                      maxCount={1}
                      action={Base_URL}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChange}
                      className={styles.exclusive_offer_priv}
                    >
                      {imgHome.length >= 1 ? (
                        <>
                          <img src={`${Offers_URL}${imgHome}`} alt="" />
                        </>
                      ) : (
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      )}
                    </Upload>
                  </ImgCrop>
                  <label className="fw-bold">
                    Banner Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 731 X 441 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={731 / 441}>
                    <Upload
                      maxCount={1}
                      onChange={BannerOnChange}
                      action={Base_URL}
                      listType="picture-card"
                    >
                      <img src={`${Offers_URL}${imageBanner}`} alt="" />
                    </Upload>
                  </ImgCrop>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Name</label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    className={styles.form_input}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                  <label className="fw-bold">Sub Category</label>
                  <Form.Control
                    type="text"
                    placeholder="Sub Category"
                    className={styles.form_input}
                    onChange={(e) => setSubCategory(e.target.value)}
                    value={subCategory}
                  />{" "}
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                  
                    <FormControlLabel
                      value="restaurants"
                      control={<Radio />}
                      checked={offerType === "restaurants" ? true : false}
                      label="Restaurant"
                      onChange={(e) => handlechangeValue(e.target.value)}
                    />
                    <FormControlLabel
                      value="retreats"
                      checked={offerType === "retreats" ? true : false}
                      control={<Radio />}
                      label="Retreats"
                      onChange={(e) => handlechangeValue(e.target.value)}
                    />
                  </RadioGroup>
                  {/* <label className="fw-bold">
                    Select Restaurant / Retreats
                  </label>
                  <Select
                    value={listData}
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    onChange={(value, label) => handleChange2(value, label)}
                    tokenSeparators={[","]}
                    options={finalList}
                  /> */}
                  <Form.Select
                    className={styles.form_input}
                    onChange={(e) => setOffersValue(e.target.value)}
                    value={offersValue}
                  >
                    <option selected>Select Restaurant / Retreats</option>

                    {finalList?.map((item, index) => {
                      return (
                        <>
                          <option value={item.value}>
                            {item.label}, {item.other}
                          </option>
                        </>
                      );
                    })}
                  </Form.Select>
                </Col>

                <Col md={6}>
                  <label className="fw-bold">Short Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Short Description"
                    className={styles.form_input}
                    value={shortDescription}
                    onChange={(e) => setShortDescription(e.target.value)}
                  />
                </Col>

                <Col md={6} className="">
                  <label className="fw-bold">Term & Condition</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Term & Condition"
                    className={styles.form_input}
                    onChange={(e) => setTermCondition(e.target.value)}
                    value={termCondition}
                  />
                  {/* <ReactQuill
                    theme="snow"
                    placeholder="Term & Condition"
                    value={termCondition}
                    onChange={setTermCondition}
                  /> */}
                </Col>
                <Col md={12}>
                  <label className="fw-bold">Package Duration</label>

                  <Form.Control
                    type="text"
                    placeholder="Package Duration"
                    className={styles.form_input}
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                  />
                </Col>
                <Col md={12} className="mb-3">
                  <label className="fw-bold">Package Description</label>

                  <ReactQuill
                    theme="snow"
                    placeholder="Package Description"
                    value={packageDescription}
                    onChange={setPackageDescription}
                  />
                </Col>
                <Col md={12} className="mb-2">
                  <label className="fw-bold">Select Pages</label>

                  <Select
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    placeholder=""
                    onChange={handleChange}
                    value={listData}
                    options={pagesList}
                  />
                </Col>
                <Col md={12} className="mb-2">
                  <label className="fw-bold">Enquiry Link</label>

                  <Form.Control
                    type="text"
                    placeholder="Enquiry Link"
                    className={styles.form_input}
                    value={enquiryLink}
                    onChange={(e) =>setEnquiryLink(e.target.value)}
                  />
                </Col>
                <label className="fw-bold">Enter Meta</label>
                <Col md={12}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Meta Title"
                    className={styles.form_input}
                    value={metaTitle}
                    onChange={(e) => setMetaTitle(e.target.value)}
                  />
                </Col>
                <Col md={12}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Meta Description"
                    className={styles.form_input}
                    value={metaDescription}
                    onChange={(e) => setMetaDescription(e.target.value)}
                  />
                  <Form.Control
                    type="text"
                    placeholder="Meta Tag"
                    className={styles.form_input}
                    value={metaTag}
                    onChange={(e) => setMetaTag(e.target.value)}
                  />
                </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={() => updateExclusiveOfferDetail(id)}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
