import ManageAdminSetting from "../pages/adminSetting";
import AddBannerAndContent from "../pages/celebration-event/bannerAndContent/addBannerAndContent";
import AddBanner from "../pages/celebration-event/bannerAndContent/addBannerAndContent";
import EditBannerAndContent from "../pages/celebration-event/bannerAndContent/editBannerAndContent";
import ManageBannerAndContent from "../pages/celebration-event/bannerAndContent/manageBannerAndContent";
import ManageEventAndBlock from "../pages/celebration-event/eventBlock/ManageEventAndBlock";
import AddEventAndBlock from "../pages/celebration-event/eventBlock/addEventAndBlock";
import EditEventAndBlock from "../pages/celebration-event/eventBlock/editEventAndBlock";
import AddCelebrationLandingPageBanner from "../pages/celebration-event/landingPage/addLandingPageBanner.js";
import ManageCelebrationLandingPageBanner from "../pages/celebration-event/landingPage/manageLandingPageBanner";
import AddAccolades from "../pages/crms/accolades/addAccolades";
import EditAccolades from "../pages/crms/accolades/editAccolades";
import ManageAccolades from "../pages/crms/accolades/manageAccolades";
import ViewAccolades from "../pages/crms/accolades/viewAccolades";
import AddContactUs from "../pages/crms/contact-us/addContactUs";
import EditContactUs from "../pages/crms/contact-us/editContantUs";
import ManageContactUs from "../pages/crms/contact-us/manageContactus";
import ManagePrivacyNotice from "../pages/crms/privacyNotice/ManagePrivacyNotice";
import AddPrivacyNotice from "../pages/crms/privacyNotice/addPrivacyNotice";
import EditPrivacyNotice from "../pages/crms/privacyNotice/editPrivacyNotice";
import ViewPrivacyNotice from "../pages/crms/privacyNotice/viewPrivacyNotice";
import AddCruiseLandingPage from "../pages/cruise/landingPage/addLandingPageBanner.js";
import EditCruiseLandingPage from "../pages/cruise/landingPage/editLandingPageBanner";
import ManageCruiseLandingPage from "../pages/cruise/landingPage/manageLandingPageBanner";
import AddCruisePackageAndDetail from "../pages/cruise/pakagesAndBanner/addPackageAndDetail";
import EditCruisePackageAndDetail from "../pages/cruise/pakagesAndBanner/editPackageAndDetail";
import ManageCruisePackageAndDetail from "../pages/cruise/pakagesAndBanner/managePackageAndDetail";
import Dashboard from "../pages/dashboard";
import CruiseEnquiryList from "../pages/enquiry/cruiseEnquiry";
import StayEnquiryList from "../pages/enquiry/stayEnquiry";
import TasteEnquiryList from "../pages/enquiry/tasteEnquiry";
import TravelEnquiryList from "../pages/enquiry/travelEnquiry/travelEnquiry";
import EnquiryList from "../pages/enquiry/travelEnquiry/travelEnquiry";
import AddExclusiveOffer from "../pages/exclusiveOffer/addExclusiveOffer";
import EditExclusiveOffer from "../pages/exclusiveOffer/editExclusiveOffer";
import EditExclusiveOfferCategory from "../pages/exclusiveOffer/exclusiveCategory/editExclusiveCategory";
import AddExclusiveOfferCategory from "../pages/exclusiveOffer/exclusiveCategory/addExclusiveCategory";
import ManageExclusiveOfferCategory from "../pages/exclusiveOffer/exclusiveCategory/manageExclusiveCategory";
import ManageExclusiveOffer from "../pages/exclusiveOffer/manageExclusiveOffer";
import AddFeaturedStory from "../pages/featureStory/addFeatureStory";
import EditFeaturedStory from "../pages/featureStory/editFeatureStory";
import ManageFeatureStory from "../pages/featureStory/manageFeatureStory";
import AddGiftCard from "../pages/giftCard/addGiftCard";
import EditGiftCard from "../pages/giftCard/editGiftCard";
import GiftApplierList from "../pages/giftCard/giftApplierList";
import ManageGiftCard from "../pages/giftCard/manageGiftCard";
import ViewGiftCardApplierList from "../pages/giftCard/viewGiftCardApplierList";
import AddJob from "../pages/job/addJob";
import AddCategory from "../pages/job/category/addCategory";
import CategoryList from "../pages/job/category/categoryList";
import EditCategory from "../pages/job/category/editCategory";
import EditJob from "../pages/job/editJob";
import ManageJobApplier from "../pages/job/job-applier/manageJobApplier";
import ViewJobApplierDetail from "../pages/job/job-applier/viewApplierDetail";
import AddDescription from "../pages/job/job-description/addDescription";
import EditJobDescription from "../pages/job/job-description/editJobDescription";
import ManageJobDescription from "../pages/job/job-description/manageJobDescription";
import ManageJobList from "../pages/job/jobList";
import ViewJob from "../pages/job/viewJobDetail";
import ManageNewsLetter from "../pages/newsLetter";
import AddRestaurant from "../pages/restaurant/addRestaurant/addRestaurant";
import ManageRestaurant from "../pages/restaurant/addRestaurant/manageRestaurant";
import AddRestaurantLandingPageBanner from "../pages/restaurant/landingPage/addLandingPageBanner.js";
import EditRestaurantLandingPageBanner from "../pages/restaurant/landingPage/editLandingPageBanner";
import ManageRestaurantLandingPageBanner from "../pages/restaurant/landingPage/manageLandingPageBanner";
import AddRestaurantPackageAndDetail from "../pages/restaurant/pakagesAndBanner/addPackageAndDetail";
import EditRestaurantPackageAndDetail from "../pages/restaurant/pakagesAndBanner/editPackageAndDetail";
import ManageRestaurantPackageAndDetail from "../pages/restaurant/pakagesAndBanner/managePackageAndDetail";
import ViewRestaurantPackageAndDetail from "../pages/restaurant/pakagesAndBanner/viewPackageAndDetail";
import AddRetreatsCategory from "../pages/retreats/addRetreatsCategory/addRetreats";
import EditRetreatsCategory from "../pages/retreats/addRetreatsCategory/editRetreats";
import ManageRetreatsCategory from "../pages/retreats/addRetreatsCategory/manageRetreats";
import AddRetreatsLandingPageBanner from "../pages/retreats/landingPage/addLandingPageBanner.js";
import EditRetreatsLandingPageBanner from "../pages/retreats/landingPage/editLandingPageBanner";
import ManageRetreatsLandingPageBanner from "../pages/retreats/landingPage/manageLandingPageBanner";
import AddRetreats from "../pages/retreats/manageRetreats/addRetreats";
import EditRetreats from "../pages/retreats/manageRetreats/editRetreats";
import ManageRetreats from "../pages/retreats/manageRetreats/manageRetreats";
import AddRoom from "../pages/retreats/manageRoom/addRoom";
import EditRoom from "../pages/retreats/manageRoom/editRoom";
import ManageRoom from "../pages/retreats/manageRoom/manageRoom";
import AddTravelLandingPageBanner from "../pages/travel/landingPage/addLandingPageBanner.js";
import EditTravelLandingPageBanner from "../pages/travel/landingPage/editLandingPageBanner";
import ManageTravelLandingPageBanner from "../pages/travel/landingPage/manageLandingPageBanner";
import AddPackageAndDetail from "../pages/travel/pakagesAndBanner/addPackageAndDetail";
import EditPackageAndDetail from "../pages/travel/pakagesAndBanner/editPackageAndDetail";
import ManagePackageAndDetail from "../pages/travel/pakagesAndBanner/managePackageAndDetail";
import AddTravelCategory from "../pages/travel/travelCategory/addTravelCategory";
import EditTravelCategory from "../pages/travel/travelCategory/editTravelCategory";
import ManageTravelCategory from "../pages/travel/travelCategory/manageTravelCategory";
import AddCruiseEnquirySection from "../pages/cruise/manageEnquiry/addEnquirySection";
import EditCruiseEnquirySection from "../pages/cruise/manageEnquiry/editEnquirySection";
import EditExperienceLandingPage from "../pages/experience/landingPage/editLandingPageBanner";
import AddExperienceLandingPage from "../pages/experience/landingPage/addLandingPageBanner.js";
import ManageExperienceLandingPage from "../pages/experience/landingPage/manageLandingPageBanner";
import ManageCruiseEnquirySection from "../pages/cruise/manageEnquiry/manageEnquirySection";
import ManageExperience from "../pages/experience/manageExperience/manageExperience";
import AddExperience from "../pages/experience/manageExperience/addExperience";
import EditExperience from "../pages/experience/manageExperience/editExperience";
import ManageExperienceCategory from "../pages/experience/addExperienceCategory/manageCategoryList";
import AddExperienceCategory from "../pages/experience/addExperienceCategory/addCategory";
import EditExperienceCategory from "../pages/experience/addExperienceCategory/editCategory";
import CelebrationEnquiryList from "../pages/celebration-event/celebrationEnquiry";
import ManageGeetingHere from "../pages/retreats/manageGettingHere/manageGettingHere";
import ManageGettingHere from "../pages/retreats/manageGettingHere/manageGettingHere";
import EditGettingHere from "../pages/retreats/manageGettingHere/editGettingHere";
import AddGettingHere from "../pages/retreats/manageGettingHere/addGettingHere";
import AddBlock from "../pages/celebration-event/blockSection/addBlock";
import EditBlock from "../pages/celebration-event/blockSection/editBlock";
import ManageBlock from "../pages/celebration-event/blockSection/ManageBlock";
import ManageSideMenu from "../pages/sideMenu/manageSidemenu";
import EditSideMenu from "../pages/sideMenu/editSideMenu";
import AddSideMenu from "../pages/sideMenu/addSideMenu";
import ManageHome from "../pages/home/manageHome";
import AddHome from "../pages/home/addHome";
import EditHome from "../pages/home/editHome";
import ManageAboutUs from "../pages/crms/about-us/manageAboutUs";
import AddAboutUs from "../pages/crms/about-us/addAboutUs";
import EditAboutUs from "../pages/crms/about-us/editAboutUs";
import AddTicket from "../pages/ticket/addTicket";
import EditTicket from "../pages/ticket/editTicket";
import ManageTicket from "../pages/ticket/manageTicket";
import AddCelebrationLandingPageContent from "../pages/celebration-event/landingPageContent/addLandingContent";
import ManageCelebrationLandingPageContent from "../pages/celebration-event/landingPageContent/manageLandingPageContent";
import EditCelebrationLandingPageContent from "../pages/celebration-event/landingPageContent/editLandingContent ";

import AddTicketBanner from "../pages/ticket/ticket-banner/addTicketBanner";
import EditTicketBanner from "../pages/ticket/ticket-banner/editTicketBanner";
import ManageTicketBanner from "../pages/ticket/ticket-banner/manageTicketBanner";
import ManagePaymentHistory from "../pages/ticket/payment-history/managePaymentHistory";
import OrderDetail from "../pages/ticket/payment-history/viewOrderDetail";
import ManageSustainabilitySeo from "../pages/manage-seo/sustainability/ManageSustainabilitySeo.js";
import EditSustainabilitySeo from "../pages/manage-seo/sustainability/editSustainabilitySeo.js";
import AddSustainabilitySeo from "../pages/manage-seo/sustainability/addSustainabilitySeo.js";
import AddContactUsSeo from "../pages/manage-seo/contact-us/addContactUsSeo.js";
import EditContactUsSeo from "../pages/manage-seo/contact-us/editContantUsSeo.js";
import ManageContactUsSeo from "../pages/manage-seo/contact-us/manageContactusSeo.js";
import ManageAccoladesSeo from "../pages/manage-seo/accolades/manageAccoladesSeo.js";
import EditAccoladesSeo from "../pages/manage-seo/accolades/editAccoladesSeo.js";
import AddAccoladesSeo from "../pages/manage-seo/accolades/addAccoladesSeo.js";
import ManageOfferSeo from "../pages/exclusiveOffer/manage-offer-seo/manageOfferSeo.js";
import AddOfferSeo from "../pages/exclusiveOffer/manage-offer-seo/addOfferSeo.js";
import EditOfferSeoOld from "../pages/exclusiveOffer/manage-offer-seo/editOfferSeoOld.js";
const adminRoutes = [
  {
    path: "/dashboard",
    exact: true,
    name: "dashboard",
    component: <Dashboard />,
  },
  {
    path: "/manage-job",
    exact: true,
    name: "manage-job",
    component: <ManageJobList />,
  },
  /*-=-=-=-=-=-=-=-=-=-=-=-Job Section routes-=-=-=-=--=-*/
  {
    path: "/add-job-detail",
    exact: true,
    name: "add-job",
    component: <AddJob />,
  },
  {
    path: "/edit-job-detail/:id",
    exact: true,
    name: "add-job",
    component: <EditJob />,
  },
  {
    path: "/view-job-detail/:id",
    exact: true,
    name: "view-job",
    component: <ViewJob />,
  },
  {
    path: "/manage-job-applier",
    exact: true,
    name: "manage-job-applier",
    component: <ManageJobApplier />,
  },
  {
    path: "/view-job-applier-detail/:id",
    exact: true,
    name: "manage-job-applier",
    component: <ViewJobApplierDetail />,
  },
  {
    path: "/add-job-description",
    exact: true,
    name: "add-job-description",
    component: <AddDescription />,
  },
  {
    path: "/edit-job-description/:id",
    exact: true,
    name: "edit-job-description",
    component: <EditJobDescription />,
  },
  {
    path: "/manage-job-description",
    exact: true,
    name: "manage-job-description",
    component: <ManageJobDescription />,
  },

  {
    path: "/add-job-category",
    exact: true,
    name: "add-job-category",
    component: <AddCategory />,
  },
  {
    path: "/edit-job-category/:id",
    exact: true,
    name: "edit-job-category",
    component: <EditCategory />,
  },
  {
    path: "/manage-category",
    exact: true,
    name: "manage-category",
    component: <CategoryList />,
  },
  /*-=-=-=-=-=-=-=-=-=-=-=-Job Section routes-=-=-=-=--=-*/

  {
    path: "/travel-enquiry-list",
    exact: true,
    name: "travel-enquiry-list",
    component: <TravelEnquiryList />,
  },
  {
    path: "/stay-enquiry-list",
    exact: true,
    name: "stay-enquiry-list",
    component: <StayEnquiryList />,
  },
  {
    path: "/taste-enquiry-list",
    exact: true,
    name: "taste-enquiry-list",
    component: <TasteEnquiryList />,
  },
  {
    path: "/cruise-enquiry-list",
    exact: true,
    name: "cruise-enquiry-list",
    component: <CruiseEnquiryList />,
  },
  {
    path: "/celebration-enquiry-list",
    exact: true,
    name: "celebration-enquiry-list",
    component: <CelebrationEnquiryList />,
  },

  /*=-=-=-=-=-=-=-=-=crms pages-=-=-=-=-=-=*/
  {
    path: "/manage-contact-us",
    exact: true,
    name: "manage-contact-ust",
    component: <ManageContactUs />,
  },
  {
    path: "/add-contact-us",
    exact: true,
    name: "add-contact-ust",
    component: <AddContactUs />,
  },
  {
    path: "/edit-contact-us/:id",
    exact: true,
    name: "edit-contact-ust",
    component: <EditContactUs />,
  },
  {
    path: "/manage-privacy-notice",
    exact: true,
    name: "manage-privacy-notice",
    component: <ManagePrivacyNotice />,
  },
  {
    path: "/add-privacy-notice",
    exact: true,
    name: "add-privacy-notice",
    component: <AddPrivacyNotice />,
  },
  {
    path: "/edit-privacy-notice/:id",
    exact: true,
    name: "edit-privacy-notice",
    component: <EditPrivacyNotice />,
  },
  {
    path: "/view-privacy-notice/:id",
    exact: true,
    name: "view-privacy-notice",
    component: <ViewPrivacyNotice />,
  },

  {
    path: "/manage-accolades",
    exact: true,
    name: "manage-accolades",
    component: <ManageAccolades />,
  },
  {
    path: "/add-accolades",
    exact: true,
    name: "add-accolades",
    component: <AddAccolades />,
  },
  {
    path: "/edit-accolades/:id",
    exact: true,
    name: "edit-accolades",
    component: <EditAccolades />,
  },
  {
    path: "/view-accolades/:id",
    exact: true,
    name: "view-accolades",
    component: <ViewAccolades />,
  },

  /*-=-=-=-=-=-=-=-=-=-=admin setting=-=-=-=-=-=*/
  {
    path: "/manage-admin-settings",
    exact: true,
    name: "manage-admin-settings",
    component: <ManageAdminSetting />,
  },
  /*=-=-=-=-=-=-=-=-=-=-=-=-=-News Letter-=-=-=-=-=-=-=-=-=*/
  {
    path: "/manage-news-letter",
    exact: true,
    name: "manage-news-letter",
    component: <ManageNewsLetter />,
  },

  /*-=-=-=-=-=-=-=-=-Feature story-=-=-=--=-=*/
  {
    path: "/manage-featured-story",
    exact: true,
    name: "manage-featured-story",
    component: <ManageFeatureStory />,
  },
  {
    path: "/add-featured-story",
    exact: true,
    name: "add-featured-story",
    component: <AddFeaturedStory />,
  },
  {
    path: "/edit-featured-story/:id",
    exact: true,
    name: "edit-featured-story",
    component: <EditFeaturedStory />,
  },

  /*-=-=-=-=-=-=-=-celebration and event-=-=-=-=-=-=-=*/
  {
    path: "/add-banner",
    exact: true,
    name: "add-banner",
    component: <AddBanner />,
  },

  /*-=-=-=-=-=-=-=-=-=-=-=-=-=-Gift Card=-=-=-=-=-=-=-=-=*/
  {
    path: "/manage-gift-card",
    exact: true,
    name: "manage-gift-card",
    component: <ManageGiftCard />,
  },
  {
    path: "/add-gift-card",
    exact: true,
    name: "add-gift-card",
    component: <AddGiftCard />,
  },
  {
    path: "/edit-gift-card/:id",
    exact: true,
    name: "edit-gift-card",
    component: <EditGiftCard />,
  },
  {
    path: "/gift-applier-list",
    exact: true,
    name: "gift-applier-list",
    component: <GiftApplierList />,
  },
  {
    path: "/view-gift-card-applier-detail/:id",
    exact: true,
    name: "view-gift-card-applier-detail",
    component: <ViewGiftCardApplierList />,
  },

  /*---------------------------offers----------------------*/
  {
    path: "/manage-exclusive-offer",
    exact: true,
    name: "manage-exclusive-offer",
    component: <ManageExclusiveOffer />,
  },
  {
    path: "/add-exclusive-offer",
    exact: true,
    name: "add-exclusive-offer",
    component: <AddExclusiveOffer />,
  },
  {
    path: "/edit-exclusive-offer/:id",
    exact: true,
    name: "edit-exclusive-offer",
    component: <EditExclusiveOffer />,
  },
  {
    path: "/edit-exclusive-offer-category/:id",
    exact: true,
    name: "edit-exclusive-offer-category",
    component: <EditExclusiveOfferCategory />,
  },
  {
    path: "/add-exclusive-offer-category",
    exact: true,
    name: "add-exclusive-offer-category",
    component: <AddExclusiveOfferCategory />,
  },
  {
    path: "/manage-exclusive-offer-category",
    exact: true,
    name: "manage-exclusive-offer-category",
    component: <ManageExclusiveOfferCategory />,
  },

  /*-=-=-=-=-=--=-=-=-=-=-celebration-==-=-=-=-=-=-=-=-=-=*/
  {
    path: "/edit-celebration-banner-content/:id",
    exact: true,
    name: "edit-celebration-banner-content",
    component: <EditBannerAndContent />,
  },
  {
    path: "/add-celebration-banner-content",
    exact: true,
    name: "add-celebration-banner-content",
    component: <AddBannerAndContent />,
  },
  {
    path: "/manage-celebration-banner-content",
    exact: true,
    name: "manage-celebration-banner-content",
    component: <ManageBannerAndContent />,
  },
  {
    path: "/add-celebration-landing-page-banner",
    exact: true,
    name: "add-celebration-landing-page-banner",
    component: <AddCelebrationLandingPageBanner />,
  },
  {
    path: "/manage-celebration-landing-page-banner",
    exact: true,
    name: "manage-celebration-landing-page-banner",
    component: <ManageCelebrationLandingPageBanner />,
  },
  {
    path: "/edit-celebration-landing-page-content/:id",
    exact: true,
    name: "edit-celebration-landing-page-content",
    component: <EditCelebrationLandingPageContent />,
  },
  {
    path: "/add-celebration-landing-page-content",
    exact: true,
    name: "add-celebration-landing-page-content",
    component: <AddCelebrationLandingPageContent />,
  },
  {
    path: "/manage-celebration-landing-page-content",
    exact: true,
    name: "manage-celebration-landing-page-content",
    component: <ManageCelebrationLandingPageContent />,
  },

  {
    path: "/manage-celebration-event-and-block",
    exact: true,
    name: "manage-celebration-event-and-block",
    component: <ManageEventAndBlock />,
  },
  {
    path: "/add-celebration-event-and-block",
    exact: true,
    name: "add-celebration-event-and-block",
    component: <AddEventAndBlock />,
  },
  {
    path: "/edit-celebration-event-and-block/:id",
    exact: true,
    name: "edit-celebration-event-and-block",
    component: <EditEventAndBlock />,
  },
  /*-=-=-=-=-=--=-=-=-=-=-Travel-==-=-=-=-=-=-=-=-=-=*/
  {
    path: "/add-travel-category",
    exact: true,
    name: "add-travel-category",
    component: <AddTravelCategory />,
  },
  {
    path: "/edit-travel-category/:id",
    exact: true,
    name: "edit-travel-category",
    component: <EditTravelCategory />,
  },
  {
    path: "/manage-travel-category",
    exact: true,
    name: "manage-travel-category",
    component: <ManageTravelCategory />,
  },
  {
    path: "/add-travel-landing-page-banner",
    exact: true,
    name: "add-travel-landing-page-banner",
    component: <AddTravelLandingPageBanner />,
  },
  {
    path: "/edit-travel-landing-page-banner/:id",
    exact: true,
    name: "edit-travel-landing-page-banner",
    component: <EditTravelLandingPageBanner />,
  },
  {
    path: "/manage-travel-landing-page-banner",
    exact: true,
    name: "/manage-travel-landing-page-banner",
    component: <ManageTravelLandingPageBanner />,
  },
  {
    path: "/add-travel-package-and-detail",
    exact: true,
    name: "add-travel-package-and-detail",
    component: <AddPackageAndDetail />,
  },
  {
    path: "/edit-travel-package-and-detail/:id",
    exact: true,
    name: "edit-travel-package-and-detail",
    component: <EditPackageAndDetail />,
  },
  {
    path: "/manage-travel-package-and-detail",
    exact: true,
    name: "manage-travel-package-and-detail",
    component: <ManagePackageAndDetail />,
  },

  /*-=-=-=-=-=-=-=-restaurant pages-==-=--=-*/
  {
    path: "/add-restaurant-landing-page-banner",
    exact: true,
    name: "add-restaurant-landing-page-banner",
    component: <AddRestaurantLandingPageBanner />,
  },
  {
    path: "/edit-restaurant-landing-page-banner/:id",
    exact: true,
    name: "edit-restaurant-landing-page-banner",
    component: <EditRestaurantLandingPageBanner />,
  },
  {
    path: "/manage-restaurant-landing-page-banner",
    exact: true,
    name: "/manage-restaurant-landing-page-banner",
    component: <ManageRestaurantLandingPageBanner />,
  },
  {
    path: "/add-restaurant-package-and-detail",
    exact: true,
    name: "add-travel-package-and-detail",
    component: <AddRestaurantPackageAndDetail />,
  },
  {
    path: "/edit-restaurant-package-and-detail/:id",
    exact: true,
    name: "edit-travel-package-and-detail",
    component: <EditRestaurantPackageAndDetail />,
  },
  {
    path: "/manage-restaurant-package-and-detail",
    exact: true,
    name: "manage-travel-package-and-detail",
    component: <ManageRestaurantPackageAndDetail />,
  },
  {
    path: "/manage-restaurant",
    exact: true,
    name: "/manage-restaurant",
    component: <ManageRestaurant />,
  },
  {
    path: "/add-restaurant",
    exact: true,
    name: "/add-restaurant",
    component: <AddRestaurant />,
  },
  {
    path: "/view-restaurant-package-and-detail/:id",
    exact: true,
    name: "view-restaurant-package-and-detail",
    component: <ViewRestaurantPackageAndDetail />,
  },

  {
    path: "/manage-retreats-category",
    exact: true,
    name: "manage-retreats-category",
    component: <ManageRetreatsCategory />,
  },
  {
    path: "/add-retreats-category",
    exact: true,
    name: "add-retreats-category",
    component: <AddRetreatsCategory />,
  },
  {
    path: "/edit-retreats-category/:id",
    exact: true,
    name: "edit-retreats-category",
    component: <EditRetreatsCategory />,
  },

  ///////////Done/////////////
  /*-=-=-=-=-=-=-retreats landing page banner-=-=-=-=-=*/
  {
    path: "/add-retreats-landing-page-banner",
    exact: true,
    name: "add-retreats-landing-page-banner",
    component: <AddRetreatsLandingPageBanner />,
  },
  {
    path: "/edit-retreats-landing-page-banner/:id",
    exact: true,
    name: "edit-retreats-landing-page-banner",
    component: <EditRetreatsLandingPageBanner />,
  },
  {
    path: "/manage-retreats-landing-page-banner",
    exact: true,
    name: "/manage-retreats-landing-page-banner",
    component: <ManageRetreatsLandingPageBanner />,
  },

  /*-=-=-=-=-=-=-=-=-=-=-manage retreats=-=-=-=-=-=-=-=-----*/
  {
    path: "/add-retreats",
    exact: true,
    name: "add-retreats",
    component: <AddRetreats />,
  },
  {
    path: "/edit-retreats/:id",
    exact: true,
    name: "edit-retreats",
    component: <EditRetreats />,
  },
  {
    path: "/manage-retreats",
    exact: true,
    name: "/manage-retreats",
    component: <ManageRetreats />,
  },

  /*-=-=-=-=-=-=-=-=-=-=-manage rooms=-=-=-=-=-=-=-=-----*/
  {
    path: "/add-room",
    exact: true,
    name: "add-room",
    component: <AddRoom />,
  },
  {
    path: "/edit-room/:id",
    exact: true,
    name: "edit-room",
    component: <EditRoom />,
  },
  {
    path: "/manage-room",
    exact: true,
    name: "/manage-room",
    component: <ManageRoom />,
  },
  /*=-=-=-=-=-=-=-=--=-=-=-=cruise landing page-=-=-=-=-=-=*/
  {
    path: "/add-cruise-landing-page",
    exact: true,
    name: "add-cruise-landing-page",
    component: <AddCruiseLandingPage />,
  },
  {
    path: "/edit-cruise-landing-page/:id",
    exact: true,
    name: "edit-cruise-landing-page",
    component: <EditCruiseLandingPage />,
  },
  {
    path: "/manage-cruise-landing-page",
    exact: true,
    name: "manage-cruise-landing-page",
    component: <ManageCruiseLandingPage />,
  },

  /*-=-=-=-=-=-=-=--= cruise package and detail-===-=-=-=-=-=-=-*/
  {
    path: "/edit-cruise-package-and-detail/:id",
    exact: true,
    name: "edit-cruise-package-and-detail",
    component: <EditCruisePackageAndDetail />,
  },
  {
    path: "/add-cruise-package-and-detail",
    exact: true,
    name: "add-cruise-package-and-detail",
    component: <AddCruisePackageAndDetail />,
  },
  {
    path: "/manage-cruise-package-and-detail",
    exact: true,
    name: "manage-cruise-package-and-detail",
    component: <ManageCruisePackageAndDetail />,
  },

  /*-=-=-=-=-=-=-=--= cruise enquiry-===-=-=-=-=-=-=-*/
  {
    path: "/edit-cruise-enquiry-section/:id",
    exact: true,
    name: "edit-cruise-enquiry-section",
    component: <EditCruiseEnquirySection />,
  },
  {
    path: "/add-cruise-enquiry-section",
    exact: true,
    name: "add-cruise-enquiry-section",
    component: <AddCruiseEnquirySection />,
  },
  {
    path: "/manage-cruise-enquiry-section",
    exact: true,
    name: "manage-cruise-enquiry-section",
    component: <ManageCruiseEnquirySection />,
  },

  /*-=-=-=-=-=-=-=--=Experience landing page-===-=-=-=-=-=-=-*/
  {
    path: "/edit-experience-landing-page/:id",
    exact: true,
    name: "edit-experience-landing-page",
    component: <EditExperienceLandingPage />,
  },
  {
    path: "/add-experience-landing-page",
    exact: true,
    name: "add-experience-landing-page",
    component: <AddExperienceLandingPage />,
  },
  {
    path: "/manage-experience-landing-page",
    exact: true,
    name: "manage-experience-landing-page",
    component: <ManageExperienceLandingPage />,
  },
  /*-=-=-=-=-=-=-=--=Experience Manage-===-=-=-=-=-=-=-*/
  {
    path: "/edit-experience/:id",
    exact: true,
    name: "edit-experience",
    component: <EditExperience />,
  },
  {
    path: "/add-experience",
    exact: true,
    name: "add-experience",
    component: <AddExperience />,
  },
  {
    path: "/manage-experience",
    exact: true,
    name: "manage-experience",
    component: <ManageExperience />,
  },

  /*-=-=-========experience category-============*/
  {
    path: "/manage-experience-category",
    exact: true,
    name: "manage-experience-category",
    component: <ManageExperienceCategory />,
  },
  {
    path: "/add-experience-category",
    exact: true,
    name: "add-experience-category",
    component: <AddExperienceCategory />,
  },
  {
    path: "/edit-experience-category/:id",
    exact: true,
    name: "edit-experience-category",
    component: <EditExperienceCategory />,
  },

  /*-=-=-=-=-=-=-Getting here =-=-=-=-=-=-=-=-*/
  {
    path: "/manage-getting-here",
    exact: true,
    name: "manage-getting-here",
    component: <ManageGettingHere />,
  },
  {
    path: "/edit-getting-here/:id",
    exact: true,
    name: "edit-getting-here",
    component: <EditGettingHere />,
  },
  {
    path: "/add-getting-here",
    exact: true,
    name: "add-getting-here",
    component: <AddGettingHere />,
  },

  /*-=-=-=-=-=-=-=-=-=-block routes-=-=-=-=-=-=-=-=-=-*/
  {
    path: "/add-celebration-block",
    exact: true,
    name: "add-block",
    component: <AddBlock />,
  },
  {
    path: "/edit-celebration-block/:id",
    exact: true,
    name: "edit-block",
    component: <EditBlock />,
  },
  {
    path: "/manage-celebration-block",
    exact: true,
    name: "manage-block",
    component: <ManageBlock />,
  },
  {
    path: "/manage-sidemenu",
    exact: true,
    name: "manage-sidemenu",
    component: <ManageSideMenu />,
  },
  {
    path: "/add-sidemenu",
    exact: true,
    name: "add-sidemenu",
    component: <AddSideMenu />,
  },
  {
    path: "/edit-sidemenu/:id",
    exact: true,
    name: "edit-sidemenu",
    component: <EditSideMenu />,
  },

  /*-=-=-===========-=-=home page manage-=-=-=-=-=-=*/
  {
    path: "/manage-homepage",
    exact: true,
    name: "manage-homepage",
    component: <ManageHome />,
  },
  {
    path: "/add-homepage",
    exact: true,
    name: "add-homepage",
    component: <AddHome />,
  },
  {
    path: "/edit-homepage/:id",
    exact: true,
    name: "edit-homepage",
    component: <EditHome />,
  },
  /*-=-=-===========-=-= About us manage-=-=-=-=-=-=*/
  {
    path: "/manage-about-us",
    exact: true,
    name: "manage-about-us",
    component: <ManageAboutUs />,
  },
  {
    path: "/add-about-us",
    exact: true,
    name: "add-about-us",
    component: <AddAboutUs />,
  },
  {
    path: "/edit-about-us/:id",
    exact: true,
    name: "edit-about-us",
    component: <EditAboutUs />,
  },
  /*-=-=-===========-=-Ticket  manage-=-=-=-=-=-=*/
  {
    path: "/manage-ticket",
    exact: true,
    name: "manage-ticket",
    component: <ManageTicket />,
  },
  {
    path: "/add-ticket",
    exact: true,
    name: "add-ticket",
    component: <AddTicket />,
  },
  {
    path: "/edit-ticket/:id",
    exact: true,
    name: "edit-ticket",
    component: <EditTicket />,
  },

  {
    path: "/manage-ticket-banner",
    exact: true,
    name: "manage-ticket",
    component: <ManageTicketBanner />,
  },
  {
    path: "/add-ticket-banner",
    exact: true,
    name: "add-ticket",
    component: <AddTicketBanner />,
  },
  {
    path: "/edit-ticket-banner/:id",
    exact: true,
    name: "edit-ticket",
    component: <EditTicketBanner />,
  },
  // payment history

  {
    path: "/manage-payment-history",
    exact: true,
    name: "manage-payment-history",
    component: <ManagePaymentHistory />,
  },
  {
    path: "/order-detail/:id",
    exact: true,
    name: "order-detail",
    component: <OrderDetail />,
  },

  // manage seo
  {
    path: "/manage-sustainability-seo",
    exact: true,
    name: "manage-sustainability-seo",
    component: <ManageSustainabilitySeo />,
  },
  {
    path: "/edit-sustainability-seo/:id",
    exact: true,
    name: "edit-sustainability-seo",
    component: <EditSustainabilitySeo />,
  },
  {
    path: "/add-sustainability-seo",
    exact: true,
    name: "add-sustainability-seo",
    component: <AddSustainabilitySeo />,
  },
  // contact seo
  {
    path: "/manage-contact-us-seo",
    exact: true,
    name: "manage-contact-us-seo",
    component: <ManageContactUsSeo />,
  },
  {
    path: "/edit-contact-us-seo/:id",
    exact: true,
    name: "edit-contact-us-seo",
    component: <EditContactUsSeo />,
  },
  {
    path: "/add-contact-us-seo",
    exact: true,
    name: "add-contact-us-seo",
    component: <AddContactUsSeo />,
  },
  // accolades seo
  {
    path: "/manage-accolades-seo",
    exact: true,
    name: "manage-accolades-seo",
    component: <ManageAccoladesSeo />,
  },
  {
    path: "/edit-accolades-seo/:id",
    exact: true,
    name: "edit-accolades-seo",
    component: <EditAccoladesSeo />,
  },
  {
    path: "/add-accolades-seo",
    exact: true,
    name: "add-accolades-seo",
    component: <AddAccoladesSeo />,
  },

  // offer seo
  {
    path: "/manage-offer-seo",
    exact: true,
    name: "manage-offer-seo",
    component: <ManageOfferSeo />,
  },
  {
    path: "/edit-offer-seo/:id",
    exact: true,
    name: "edit-offer-seo",
    component: <EditOfferSeoOld />,
  },
  {
    path: "/add-offer-seo",
    exact: true,
    name: "add-offer-seo",
    component: <AddOfferSeo />,
  },
];
export default adminRoutes;
