import axiosInstance from "./../api";
const token = localStorage.getItem("token");

export const addPrivacyNotice = async (privacyNoticeData) => {

  const data = new FormData();

  data.append("text", privacyNoticeData.discription);
  data.append("meta_title", privacyNoticeData.metaTitle);
  data.append("meta_description", privacyNoticeData.metaDescription);
  data.append("meta_tag", privacyNoticeData.metaTag);

  const response = await axiosInstance.request({
    url: "addprivacy",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const showPrivacyNoticeData = async () => {
  const response = await axiosInstance.request({
    url: "showprivacy",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const editPrivacyNotice = async (id) => {
 
  const response = await axiosInstance.request({
    url: `privacydataid/${id}`,
    method: "GET",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const updatePrivacyNotice = async (privacyNoticeData, id) => {
  const data = new FormData();
  data.append("text", privacyNoticeData.discription);
  data.append("id", id);
  data.append("meta_title", privacyNoticeData.metaTitle);
  data.append("meta_description", privacyNoticeData.metaDescription);
  data.append("meta_tag", privacyNoticeData.metaTag);

  const response = await axiosInstance.request({
    url: "updateprivacy",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};


export const AddAccoladesMeta = async (privacyNoticeData) => {

  const data = new FormData();

  data.append("title", privacyNoticeData.metaTitle);
  data.append("description", privacyNoticeData.metaDescription);
  data.append("tag", privacyNoticeData.metaTag);

  const response = await axiosInstance.request({
    url: "addaccoladesMeta",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const showAccoladesMeta = async () => {
  const response = await axiosInstance.request({
    url: "showaccoladesMeta",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const AddContactMeta = async (privacyNoticeData) => {

  const data = new FormData();

  data.append("title", privacyNoticeData.metaTitle);
  data.append("description", privacyNoticeData.metaDescription);
  data.append("tag", privacyNoticeData.metaTag);

  const response = await axiosInstance.request({
    url: "addContactMeta",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const AddOfferMeta = async (privacyNoticeData) => {

  const data = new FormData();

  data.append("title", privacyNoticeData.metaTitle);
  data.append("description", privacyNoticeData.metaDescription);
  data.append("tag", privacyNoticeData.metaTag);

  const response = await axiosInstance.request({
    url: "addoffersMeta",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const showOffersMeta = async () => {
  const response = await axiosInstance.request({
    url: "showOffersMeta",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const showContactMeta = async () => {
  const response = await axiosInstance.request({
    url: "showacontactMeta",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const AddSustainabilityMeta = async (privacyNoticeData) => {

  const data = new FormData();

  data.append("title", privacyNoticeData.metaTitle);
  data.append("description", privacyNoticeData.metaDescription);
  data.append("tag", privacyNoticeData.metaTag);

  const response = await axiosInstance.request({
    url: "addSustainabilityMeta",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const showSustainabilityMeta = async () => {
  const response = await axiosInstance.request({
    url: "showaSustainabilityMeta",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};