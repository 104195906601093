import React, { useEffect, useState } from "react";
import styles from "./../travel.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Offers_URL } from "../../../../serverUrl";
import {
  addCelebrationBannerImage,
  addCelebrationData,
  deleteImage,
} from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
import {
  UploadIcon,
  addInputIcon,
  crossRed,
  deleteIcon,
} from "../../../../utils/svg.file";
import {
  addTravelPackageDetail,
  showTravelName,
} from "../../../../api/adminPanel/travelApi";
import ReactQuill from "react-quill";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";
import { FormControlLabel, Switch } from "@mui/material";
export default function AddPackageAndDetail() {
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [travelCategoryItem, setTravelCategoryItem] = useState("");
  const [titledata, setTitledata] = useState("");
  const [description, setDescription] = useState("");
  const [imageBannerArray, setImageBannerArray] = useState([]);
  const [imgHome, setImgBanner] = useState("");
  const [fileList, setFileList] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  // const [hightlight, setHighLight] = useState("");
  const [priceSection, setPriceSection] = useState("");
  const [guidedTour, setGuidedTour] = useState("");
  const [termCondition, setTermCondition] = useState("");
  const [imageGallery, setImageGallery] = useState("");
  const [travelCategory, setTravelCategory] = useState([]);
  const [priceMain, setPriceMain] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaTag, setMetaTag] = useState("");

  const [cardImage, setCardImage] = useState("");
  const [mapImage, setMapImage] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [contactField, setContactField] = useState([
    {
      dayCount: "",
      dayDescription: "",
      dayOrder: "",
    },
  ]);
  const [highlightField, setHightlightField] = useState([
    {
      highlightText: "",
      highlightOrder: "",
    },
  ]);
  const [termConditionField, setTermConditionField] = useState([
    {
      termCondition: "",
      termOrder: "",
    },
  ]);
  const [priceField, setPriceField] = useState([
    {
      priceMain: "",
    },
  ]);
  const [guideField, setGuideField] = useState([
    {
      guideType: "",
      guideSubType: "",

      guideMain: "",
      guideOrder: "",
    },
  ]);

  // const getSrcFromFile = (file) => {
  //   return new Promise((resolve) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file.originFileObj);
  //     reader.onload = () => resolve(reader.result);
  //   });
  // };
  const onChange = async ({ fileList }) => {
    setFileList(fileList);
    setImgBanner(fileList[0].originFileObj);
  };
  const BannerOnChange = async ({ fileList }) => {
    setFileList(fileList);
    setImageGallery(fileList[0].originFileObj);
    const addCelebrationImagePayload = {
      image: fileList[0].originFileObj,
    };
    const response = await addCelebrationBannerImage(
      addCelebrationImagePayload
    );

    if (response.remote === "success") {
      swal("Image Successfully Added");
      setImageBannerArray([...imageBannerArray, response.data.data]);
      setFileList([]);
    } else {
    }
  };

  // const onPreview = async (file) => {
  //   const src = file.url || (await getSrcFromFile(file));
  //   const imgWindow = window.open(src);

  //   if (imgWindow) {
  //     const image = new Image();
  //     image.src = src;
  //     imgWindow.document.write(image.outerHTML);
  //   } else {
  //     window.location.href = src;
  //   }
  // };
  const handelDeleteImgae = async (image) => {
    const newImageArray = imageBannerArray.filter(
      (imageBannerArray) => imageBannerArray !== image
    );
    setImageBannerArray(newImageArray);
    const deleteimages = {
      image: image,
    };

    const response = await deleteImage(deleteimages);

    if (response.remote === "success") {
      swal("Image Delete succesfully!");
    } else {
      swal("Something Went Wrong!");
    }
  };

  const firstUpload = {
    action: { Base_URL },
    listType: "picture-card",
    defaultFileList: [...fileList],
    maxCount: "1",
  };

  const handleHightlightChange = (index, event) => {
    let data = [...highlightField];
    data[index][event.target.name] = event.target.value;
    setHightlightField(data);
  };
  const addFieldsHightlight = () => {
    let newfieldHighlight = {
      highlightText: "",
      highlightOrder: "",
    };

    setHightlightField([...highlightField, newfieldHighlight]);
  };
  const handleFormChange = (index, event) => {
    let data = [...contactField];
    data[index][event.target.name] = event.target.value;
    setContactField(data);
  };

  const handleTermConditionChange = (index, event) => {
    let data = [...termConditionField];
    data[index][event.target.name] = event.target.value;
    setTermConditionField(data);
  };

  const addFieldsTermCondition = () => {
    let newfieldTermCondition = {
      termCondition: "",
      termOrder: "",
    };

    setTermConditionField([...termConditionField, newfieldTermCondition]);
  };

  const addFields = () => {
    let newfield = {
      dayCount: "",
      dayDescription: "",
      dayOrder: "",
    };

    setContactField([...contactField, newfield]);
  };
  const removeFieldsHighlight = (index) => {
    let data = [...highlightField];
    data.splice(index, 1);
    setHightlightField(data);
  };
  const removeFields = (index) => {
    let data = [...contactField];
    data.splice(index, 1);
    setContactField(data);
  };
  const removeFieldsTermsCondition = (index) => {
    let data = [...termConditionField];
    data.splice(index, 1);
    setTermConditionField(data);
  };
  const handleGuideChange = (index, event) => {
    let data = [...guideField];
    data[index][event.target.name] = event.target.value;
    setGuideField(data);
  };
  const addFieldsGuide = () => {
    let newfieldGuide = {
      guideType: "",
      guideSubType: "",
      guideMain: "",
      guideOrder: "",
    };

    setGuideField([...guideField, newfieldGuide]);
  };
  const removeFieldsGuide = (index) => {
    let data = [...guideField];
    data.splice(index, 1);
    setGuideField(data);
  };

  const handlePriceChange = (index, event) => {
    let data = [...priceField];
    data[index][event.target.name] = event.target.value;
    setPriceField(data);
  };
  const addFieldsPrice = () => {
    let newfieldPrice = {
      priceMain: "",
    };

    setPriceField([...priceField, newfieldPrice]);
  };
  const removeFieldsPrice = (index) => {
    let data = [...priceField];
    data.splice(index, 1);
    setPriceField(data);
  };

  const handleTravelCategory = async () => {
    const response = await showTravelName();

    if (response.remote === "success") {
      setTravelCategory(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleTravelCategory();
  }, []);



  const handleTravelPackageDetails = async () => {
    const addTravelPcakagedata = {
      cardImage: imgHome,
      banner: imageBannerArray,
      highlights: highlightField,
      itnery: contactField,
      price: priceMain,
      tour: guideField,
      terms: termConditionField,
      longdescription: longDescription,
      // description: restaurant,
      title: titledata,
      shortDescription: description,
      category: travelCategoryItem,
      mapImage: mapImage,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
      metaTag: metaTag,
    };
    const response = await addTravelPackageDetail(addTravelPcakagedata);

    if (response.remote === "success") {
      if(response.data.errors){

        swal("Please Select Category");
      }else{

        swal("Data Added Successfully");
        navigate("/manage-travel-package-and-detail");
      }
    } else {
    }
  };

  const onChangeMap = async ({ fileList }) => {
    // setFileListCard(fileListCard);

    setMapImage(fileList[0].originFileObj);
  };
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Package & Detail</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">Category</label>
                  <Form.Group className="">
                    <Form.Select
                      className={styles.form_input}
                      onChange={(e) => setTravelCategoryItem(e.target.value)}
                    >
                      <option selected>Select Category </option>
                      {travelCategory?.map((item, index) => {
                        return (
                          <>
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          </>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <label className="fw-bold">Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setTitledata(e.target.value)}
                  />
                </Col>
                <Col md={3}>
                  <label className="fw-bold mb-2">
                    {" "}
                    Preview Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 818 X 814 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={818 / 814}>
                    <Upload {...firstUpload} onChange={onChange}>
                      {fileList.length < 1 && (
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      )}
                    </Upload>
                  </ImgCrop>
                  <ul className={styles.multi_image_preview}>
                    {/* <li className={styles.upload_list_image}>
                       <img src={`${Offers_URL}${fileList.name}`} alt="" /> 
                      <div className={styles.action_btn}>
                        <span
                          className={styles.delete_icon_btn}
                          // onClick={() => handelDeleteImgae(item)}
                        >
                          {crossRed}
                        </span>
                      </div>
                    </li> */}
                  </ul>
                </Col>
                <Col md={9} className="multi-images">
                  <label className="fw-bold mb-2">
                    Banner Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 1219 X 757 px)
                    </small>
                  </label>
                  <div className="d-flex">
                    <ImgCrop grid aspect={1219 / 757}>
                      <Upload
                        maxCount={1}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                        onChange={BannerOnChange}
                      >
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                    </ImgCrop>
                    <ul className={styles.multi_image_preview}>
                      {imageBannerArray?.map((item, index) => {
                        return (
                          <>
                            <li className={styles.upload_list_image}>
                              <img src={`${Offers_URL}${item}`} alt="" />
                              <div className={styles.action_btn}>
                                <span
                                  className={styles.delete_icon_btn}
                                  onClick={() => handelDeleteImgae(item)}
                                >
                                  {crossRed}
                                </span>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <label className="fw-bold">Short Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Col>
                <Col md={12} className="mb-3">
                  <label className="fw-bold">Long Description</label>
                  <ReactQuill
                    rows="3"
                    type="text"
                    placeholder="Description"
                    // className={styles.form_input}
                    value={longDescription}
                    onChange={setLongDescription}
                  />
                </Col>
                <hr></hr>
                <Col md={12}>
                  <label className="fw-bold">Highlights</label>
                  {highlightField.map((item, index) => {
                    return (
                      <>
                        <Row gutter={[24, 0]} key={index}>
                          <Col md={8}>
                            {" "}
                            <Form.Control
                              type="text"
                              name="highlightText"
                              placeholder="Highlight Text"
                              className={styles.form_input}
                              defaultValue={item.hightlightText}
                              onChange={(event) =>
                                handleHightlightChange(index, event)
                              }
                            />
                          </Col>
                          <Col md={2}>
                            {" "}
                            <Form.Control
                              type="text"
                              name="highlightOrder"
                              placeholder="Order No."
                              className={styles.form_input}
                              defaultValue={item.hightlightOrder}
                              onChange={(event) =>
                                handleHightlightChange(index, event)
                              }
                            />
                          </Col>

                          <Col md={2} className="text-end">
                            <span
                              className={styles.edit_icon_btn}
                              onClick={() => addFieldsHightlight(index)}
                            >
                              {addInputIcon}
                            </span>
                            {highlightField.length > 1 ? (
                              <span
                                className={styles.delete_icon_btn}
                                onClick={() => removeFieldsHighlight(index)}
                              >
                                {deleteIcon}
                              </span>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>

                        <hr />
                      </>
                    );
                  })}
                </Col>
                <Col md={12}>
                  <label className="fw-bold">Itinary</label>
                  {contactField.map((item, index) => {
                    return (
                      <>
                        <Row gutter={[24, 0]} key={index}>
                          <Col md={2}>
                            {" "}
                            <Form.Control
                              type="text"
                              name="dayCount"
                              placeholder="Day Count"
                              className={styles.form_input}
                              defaultValue={item.dayCount}
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                            />
                          </Col>
                          <Col md={6}>
                            {" "}
                            <Form.Control
                              type="text"
                              name="dayDescription"
                              placeholder="Day Description"
                              className={styles.form_input}
                              defaultValue={item.dayDecsription}
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                            />
                          </Col>
                          <Col md={2}>
                            {" "}
                            <Form.Control
                              type="text"
                              name="dayOrder"
                              placeholder="Order No."
                              className={styles.form_input}
                              defaultValue={item.dayOrder}
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                            />
                          </Col>
                          <Col md={2} className="text-end">
                            <span
                              className={styles.edit_icon_btn}
                              onClick={() => addFields(index)}
                            >
                              {addInputIcon}
                            </span>
                            {contactField.length > 1 ? (
                              <span
                                className={styles.delete_icon_btn}
                                onClick={() => removeFields(index)}
                              >
                                {deleteIcon}
                              </span>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>

                        <hr />
                      </>
                    );
                  })}
                </Col>
                <Col md={8}>
                  <label className="fw-bold">
                    Price (Excluded International Airfare)
                  </label>
                 
                  <Row gutter={[24, 0]}>
                    <Col md={12}>
                      <ReactQuill
                        name="priceMain"
                        // theme="snow"
                        value={priceMain}
                        onChange={setPriceMain}
                      />
                    </Col>
                  </Row>

                  <hr />
                  {/* </>
                    );
                  })} */}
                </Col>
                <Col md={4}>
                  {" "}
                  <label className="fw-bold">
                    Map Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 1219 X 930 px)
                    </small>
                  </label>{" "}
                  <div className="flex single_image_upload">
                    <ImgCrop grid aspect={1219 / 930}>
                      <Upload
                        maxCount={1}
                        onChange={onChangeMap}
                        action={Base_URL}
                        listType="picture-card"
                        // defaultFileList={[...fileList]}
                      >
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      </Upload>
                    </ImgCrop>
                  </div>
                </Col>
                <Col md={12}>
                  <label className="fw-bold">
                    Guided Tour / Self Guided Tour
                  </label>
                  {guideField.map((item, index) => {
                    return (
                      <>
                        <Row gutter={[24, 0]} key={index}>
                          <Col md={3}>
                            <Form.Control
                              type="text"
                              name="guideType"
                              className={styles.form_input}
                              value={item.guideType}
                              onChange={(event) =>
                                handleGuideChange(index, event)
                              }
                            />
                          </Col>
                          <Col md={3}>
                            <select
                              type="text"
                              name="guideSubType"
                              className={styles.form_input}
                              defaultValue={item.guideSubType}
                              onChange={(event) =>
                                handleGuideChange(index, event)
                              }
                            >
                              <option selected>Select Sub type</option>
                              <option value="Includes">Includes</option>
                              <option value="Excludes">Excludes</option>
                            </select>
                          </Col>
                          <Col md={2}>
                            {" "}
                            <Form.Control
                              type="text"
                              name="guideMain"
                              placeholder=""
                              className={styles.form_input}
                              defaultValue={item.guideMain}
                              onChange={(event) =>
                                handleGuideChange(index, event)
                              }
                            />
                          </Col>

                          <Col md={2}>
                            {" "}
                            <Form.Control
                              type="text"
                              name="guideOrder"
                              placeholder="Order No."
                              className={styles.form_input}
                              defaultValue={item.guideOrder}
                              onChange={(event) =>
                                handleGuideChange(index, event)
                              }
                            />
                          </Col>
                          <Col md={2} className="text-end">
                            <span
                              className={styles.edit_icon_btn}
                              onClick={() => addFieldsGuide(index)}
                            >
                              {addInputIcon}
                            </span>
                            {guideField.length > 1 ? (
                              <span
                                className={styles.delete_icon_btn}
                                onClick={() => removeFieldsGuide(index)}
                              >
                                {deleteIcon}
                              </span>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>

                        <hr />
                      </>
                    );
                  })}
                </Col>
                <Col md={12}>
                  <label className="fw-bold">Term & Condition</label>
                  {termConditionField.map((item, index) => {
                    return (
                      <>
                        <Row gutter={[24, 0]} key={index}>
                          <Col md={8}>
                            {" "}
                            <Form.Control
                              type="text"
                              name="termCondition"
                              placeholder="Term & Condition"
                              className={styles.form_input}
                              defaultValue={item.termConditionText}
                              onChange={(event) =>
                                handleTermConditionChange(index, event)
                              }
                            />
                          </Col>

                          <Col md={2}>
                            {" "}
                            <Form.Control
                              type="text"
                              name="termOrder"
                              placeholder="Order No."
                              className={styles.form_input}
                              defaultValue={item.termConditionOrder}
                              onChange={(event) =>
                                handleTermConditionChange(index, event)
                              }
                            />
                          </Col>
                          <Col md={2} className="text-end">
                            <span
                              className={styles.edit_icon_btn}
                              onClick={() => addFieldsTermCondition(index)}
                            >
                              {addInputIcon}
                            </span>
                            {termConditionField.length > 1 ? (
                              <span
                                className={styles.delete_icon_btn}
                                onClick={() =>
                                  removeFieldsTermsCondition(index)
                                }
                              >
                                {deleteIcon}
                              </span>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>

                        <hr />
                      </>
                    );
                  })}
                </Col>
                <Col md={3}>
                  {" "}
                  <FormControlLabel
                    className="switch_admin"
                    control={<Switch defaultChecked />}
                    label="Show On Homepage"
                  />
                </Col>
                <label className="fw-bold">Enter Meta</label>
                <Col md={12}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Meta Title"
                    className={styles.form_input}
                    onChange={(e) => setMetaTitle(e.target.value)}
                  />
                </Col>
                <Col md={12}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Meta Description"
                    className={styles.form_input}
                    onChange={(e) => setMetaDescription(e.target.value)}
                  />
                </Col>
                <Col md={12}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Meta Tag"
                    className={styles.form_input}
                    onChange={(e) => setMetaTag(e.target.value)}
                  />
                </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handleTravelPackageDetails}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
