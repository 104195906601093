import React, { useEffect, useState } from "react";
import styles from "./../travel.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { imageListClasses } from "@mui/material";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Offers_URL } from "../../../../serverUrl";
import { UploadIcon, crossRed } from "../../../../utils/svg.file";
import {
  AddtravelBanner,
  UpdatetravelBanner,
  findTravelBanner,
} from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
export default function EditTravelLandingPageBanner() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [description, setDescription] = useState("");
  const [imgHome, setImgBanner] = useState("");
  const [fileList, setFileList] = useState([]);
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaTag, setMetaTag] = useState("");
  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };
  const onChange = ({ fileList }) => {
    setFileList(fileList);
    setImgBanner(fileList[0].originFileObj);
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const handeltravelBanner = async () => {
    const travelData = {
      id: id,
      image: imgHome,
      description: description,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
      metaTag: metaTag,
    };

    const response = await UpdatetravelBanner(travelData);

    if (response.remote === "success") {
      // setDescription("");
      swal("Data Updated Successfully!");
    } else {
      swal("Something Went Wrong!");
    }
  };
  const handelLandingPagedata = async (id) => {
    const response = await findTravelBanner(id);

    if (response.remote === "success") {
      setDescription(response.data.data.description);
      setImgBanner(response.data.data.image);
      setMetaTitle(response.data.data.meta_title);
      setMetaDescription(response.data.data.meta_description);
      setMetaTag(response.data.data.meta_tag);
    } else {
      swal("Something Went Wrong!");
    }
  };

  useEffect(() => {
    handelLandingPagedata(id);
  }, [id]);
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Edit Landing Page Banner</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">
                    Banner Image{" "}
                    <small className="text-success">
                      (Max. Size 1219 X 757 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={1219 / 757}>
                    <Upload
                      action={Base_URL}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChange}
                      onPreview={onPreview}
                    >
                      {imgHome.length >= 1 ? (
                        <>
                          <img src={`${Offers_URL}${imgHome}`} alt="" />
                        </>
                      ) : (
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      )}
                    </Upload>
                  </ImgCrop>
                </Col>{" "}
                <Col md={6}>
                  <label className="fw-bold">Short Description</label>

                  <textarea
                    rows="4"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Col>{" "}
                <label className="fw-bold">Enter Meta</label>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Title"
                      className={styles.form_input}
                      value={metaTitle}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Col>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Description"
                      className={styles.form_input}
                      value={metaDescription}
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Col>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Tag"
                      className={styles.form_input}
                      value={metaTag}
                      onChange={(e) => setMetaTag(e.target.value)}
                    />
                  </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handeltravelBanner}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
