import React, { useEffect, useState } from "react";
import styles from "./home.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL } from "../../../serverUrl";
import { UploadIcon } from "../../../utils/svg.file";
import swal from "sweetalert";
import { addhomedata } from "../../../api/adminPanel/featuredStory";
export default function AddHome() {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [stayPic, setStayPic] = useState("");
  const [diningPic, setDiningPic] = useState("");
  const [travelPic, setTravelPic] = useState("");
  const [description, setDescription] = useState("");
  const [homeTitle, setHomeTitle] = useState("");
  const [diningdescription, setdiningDescription] = useState("");
  const [traveldescription, settravelDescription] = useState("");
  const [staydescription, setstayDescription] = useState("");
  const [diningtitle, setdiningTitle] = useState("");
  const [traveltitle, settravelTitle] = useState("");
  const [staytitle, setstayTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("")
  const [metaTag, setMetaTag] = useState("")
  const onChangeStay = async ({ fileList }) => {
    setFileList(fileList);
    setStayPic(fileList[0].originFileObj);
  };
  const onChangeDining = async ({ fileList }) => {
    setFileList(fileList);
    setDiningPic(fileList[0].originFileObj);
  };
  const onChangeTravel = async ({ fileList }) => {
    setFileList(fileList);
    setTravelPic(fileList[0].originFileObj);
  };
  const firstUpload = {
    action: { Base_URL },
    listType: "picture-card",
    defaultFileList: [...fileList],
    maxCount: "1",
  };
  const handleHomeData = async () => {
    const homedata = {
      description: description,
      stayPic: stayPic,
      diningPic: diningPic,
      travelPic: travelPic,
      diningdescription: diningdescription,
      traveldescription: traveldescription,
      staydescription: staydescription,
      diningtitle: diningtitle,
      traveltitle: traveltitle,
      staytitle: staytitle,
      homeTitle: homeTitle,
      
      metaTitle: metaTitle,
      metaDescription: metaDescription,
      metaTag: metaTag,
    
    };
    const response = await addhomedata(homedata);

    if (response.remote === "success") {
      swal("SideBar Successfully Added");
      navigate("/manage-homepage");
  
    } else {
    }
  };
  useEffect(() => {}, []);
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add HomePage</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={12}>
                  <label className="fw-bold">Home Page Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e)=>setHomeTitle(e.target.value)}
                  />
                </Col>
                <Col md={12}>
                  <label className="fw-bold">Description</label>
                  <Form.Group className="">
                    <textarea
                      rows="3"
                      type="text"
                      placeholder="Description"
                      className={styles.form_input}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <hr></hr>
                <label class="fw-bold mb-3">Retreat</label>
                <Col md={12}>
                  <label className="fw-bold">Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setstayTitle(e.target.value)}
                  />
                </Col>

                <Col md={6}>
                  <label className="fw-bold mb-2">Retreat Description</label>
                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={(e) => setstayDescription(e.target.value)}
                  />
                </Col>
                <Col md={3}>
                  <label className="fw-bold mb-2">
                    {" "}
                    Preview Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 675 X 631 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={675 / 631}>
                    <Upload {...firstUpload} onChange={onChangeStay}>
                      {stayPic.length < 1 && (
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      )}
                    </Upload>
                  </ImgCrop>
                </Col>
                <hr></hr>
                <label class="fw-bold mb-3">Restaurants</label>
                <Col md={12}>
                  <label className="fw-bold">Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => setdiningTitle(e.target.value)}
                  />
                </Col>

                <Col md={6}>
                  <label className="fw-bold mb-2">
                    Restaurants Description
                  </label>
                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={(e) => setdiningDescription(e.target.value)}
                  />
                </Col>
                <Col md={3}>
                  <label className="fw-bold mb-2">
                    {" "}
                    Preview Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 460 X 613 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={460 / 613}>
                    <Upload {...firstUpload} onChange={onChangeDining}>
                      {diningPic.length < 1 && (
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      )}
                    </Upload>
                  </ImgCrop>
                </Col>
                <hr></hr>
                <label class="fw-bold mb-3">Travel Section</label>
                <Col md={12}>
                  <label className="fw-bold">Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    onChange={(e) => settravelTitle(e.target.value)}
                  />
                </Col>

                <Col md={6}>
                  <label className="fw-bold mb-2">Travel Description</label>
                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={(e) => settravelDescription(e.target.value)}
                  />
                </Col>
                <Col md={3}>
                  <label className="fw-bold mb-2">
                    {" "}
                    Preview Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 782 X 471 px)
                    </small>
                  </label>
                  <ImgCrop grid aspect={782 / 471}>
                    <Upload {...firstUpload} onChange={onChangeTravel}>
                      {travelPic.length < 1 && (
                        <>
                          <div className="file_upload_div">
                            <span>{UploadIcon}</span>
                            <p>Click Here to Upload</p>
                          </div>
                        </>
                      )}
                    </Upload>
                  </ImgCrop>
                </Col>
                <label className="fw-bold">Enter Meta</label>
                <Col md={12}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Meta Title"
                    className={styles.form_input}
                    onChange={(e) => setMetaTitle(e.target.value)}
                  />
                </Col>
                <Col md={12}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Meta Description"
                    className={styles.form_input}
                    onChange={(e) => setMetaDescription(e.target.value)}
                  />
                </Col>
                <Col md={12}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Meta Tag"
                    className={styles.form_input}
                    onChange={(e) => setMetaTag(e.target.value)}
                  />
                </Col>
                <Col md={12} className="text-center  mt-3">
                  <Button
                    className={styles.main_button}
                    onClick={() => handleHomeData()}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
