import axiosInstance from "../api";
const token = localStorage.getItem("token");

export const editCategory = async (id) => {
  const response = await axiosInstance.request({
    url: `jobCategoryid/${id}`,
    method: "GET",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const updateCategory = async (jobCategoryData, id) => {
  const data = new FormData();
  data.append("name", jobCategoryData.category);
  data.append("id", id);
  const response = await axiosInstance.request({
    url: "updatejobCategory",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const deleteCategory = async (id) => {
  const response = await axiosInstance.request({
    url: `deletejobCategory/${id}`,
    method: "DELETE",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

/*-=-=-=------=-=-=-=-=-=-=job decsription api-=-=-=-=-=-=-=*/
export const editDescription = async (id) => {
  const response = await axiosInstance.request({
    url: `id_job_page_front/${id}`,
    method: "GET",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const updatedescription = async (jobDescriptionData, id) => {
  const data = new FormData();
  data.append("discription", jobDescriptionData.description);
  data.append("id", id);
  data.append("meta_title", jobDescriptionData.metaTitle);
  data.append("meta_description", jobDescriptionData.metaDescription);
  data.append("meta_tag", jobDescriptionData.metaTag);
  const response = await axiosInstance.request({
    url: "update_job_page_front",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

/*-=-=-=------=-=-=-=-=-=-=job detail api-=-=-=-=-=-=-=*/
export const editJobDetail = async (id) => {
  const response = await axiosInstance.request({
    url: `jobwithId/${id}`,
    method: "GET",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const updateJobDetail = async (jobDescriptionData, id) => {
  const data = new FormData();
  data.append("discription", jobDescriptionData.description);
  data.append("designation", jobDescriptionData.designation);

  data.append("state", jobDescriptionData.state);

  data.append("location", jobDescriptionData.location);

  data.append("category", jobDescriptionData.category);

  data.append("id", id);

  const response = await axiosInstance.request({
    url: "updatejob",
    method: "POST",
    data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const deleteJob = async (id) => {
  const response = await axiosInstance.request({
    url: `deletejob/${id}`,
    method: "DELETE",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

/*=-=-=-=-=-=-=-=-=-=job applier api-=-=-=-=-=-=*/
export const getJobApplierList = async (id) => {
  const response = await axiosInstance.request({
    url: "applyjobslist",
    method: "GET",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const showJobApplierDetail = async (id) => {
 
  const response = await axiosInstance.request({
    url: `applylist/${id}`,
    method: "GET",
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
