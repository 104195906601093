import React, { useEffect, useState } from "react";
import styles from "./../experience.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Offers_URL } from "../../../../serverUrl";
import { FindExperienceBannerList, UpdateExperienceLandingDetails } from "../../../../api/adminPanel/giftCardApi";
import swal from "sweetalert";
import { UploadIcon } from "../../../../utils/svg.file";
export default function AddExperienceLandingPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [description, setDescription] = useState("");
  const [imageBanner, setImageBanner] = useState("");
  const [fileList, setFileList] = useState([]);
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaTag, setMetaTag] = useState("");
  const BannerOnChange = async ({ fileList }) => {
    // setFileList(fileList);
    setImageBanner(fileList[0].originFileObj);
  };
  const handleExperienceDetails = async (id) => {
    const response = await FindExperienceBannerList(id);

    if (response.remote === "success") {
      setImageBanner(response.data.data.banner);
      setDescription(response.data.data.description);
      setMetaTitle(response.data.data.meta_title);
      setMetaDescription(response.data.data.meta_description);
      setMetaTag(response.data.data.meta_tag);
    } else {
    }
  };

  const handleUpdateExperienceDetails = async () => {
    const restaurantData = {
      id: id,
      banerImage: imageBanner,
      description: description,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
      metaTag: metaTag,
    };
    const response = await UpdateExperienceLandingDetails(restaurantData);

    if (response.remote === "success") {
      swal("Data Added Successfully");
      navigate("/manage-experience-landing-page");
    } else {
    }
  };
  useEffect(() => {
    handleExperienceDetails(id);
  }, [id]);
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Edit Stories Landing Page</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={3}>
                  <label className="fw-bold">
                    Banner Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 1219 X 757 px)
                    </small>
                  </label>
                  <div className="flex single_image_upload">
                    <ImgCrop grid aspect={1219 / 757}>
                      <Upload
                        maxCount={1}
                        onChange={BannerOnChange}
                        action={Base_URL}
                        listType="picture-card"
                        defaultFileList={[...fileList]}
                        className="banner_single"
                      >
                        {imageBanner.length >= 1 ? (
                          <>
                            <img
                              src={`${Offers_URL}${imageBanner}`}
                              alt=""
                              // className={styles.preview_img}
                            />
                          </>
                        ) : (
                          <>
                            <div className="file_upload_div">
                              <span>{UploadIcon}</span>
                              <p>Click Here to Upload</p>
                            </div>
                          </>
                        )}
                      </Upload>
                    </ImgCrop>
                  </div>
                </Col>
                <Col md={12}>
                  <label className="fw-bold">Description</label>

                  <ReactQuill
                    rows="5"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={setDescription}
                    value={description}
                  />
                </Col>
                <label className="fw-bold">Enter Meta</label>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Title"
                      className={styles.form_input}
                      value={metaTitle}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Col>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Description"
                      className={styles.form_input}
                      value={metaDescription}
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Col>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Tag"
                      className={styles.form_input}
                      value={metaTag}
                      onChange={(e) => setMetaTag(e.target.value)}
                    />
                  </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handleUpdateExperienceDetails}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
