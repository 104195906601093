import axiosInstance from "./../api";
const token = localStorage.getItem("token");

export const addFeaturedStory = async (featuredStoryData) => {
 
  const data = new FormData();
  data.append("image", featuredStoryData.image);
  data.append("heading", featuredStoryData.heading);

  data.append("discription", featuredStoryData.discription);

  const response = await axiosInstance.request({
    url: "addfeaturestory",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const addsidebar = async (featuredStoryData) => {
 
  const data = new FormData();
  data.append("image", featuredStoryData.image);
  data.append("type", featuredStoryData.heading);

  data.append("discription", featuredStoryData.discription);

  const response = await axiosInstance.request({
    url: "addsidebar",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const updatesideBar = async (featuredStoryData) => {
 
  const data = new FormData();
  data.append("id", featuredStoryData.id);
  data.append("image", featuredStoryData.image);
  data.append("type", featuredStoryData.heading);

  data.append("discription", featuredStoryData.discription);

  const response = await axiosInstance.request({
    url: "updatesidebar",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const showFeaturedStoryList = async () => {
  const response = await axiosInstance.request({
    url: "showfeaturestory",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const showSidebarList = async () => {
  const response = await axiosInstance.request({
    url: "listsidebar",
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const findSidebarList = async (id) => {
  const response = await axiosInstance.request({
    url: `findsidebar/${id}`,
    method: "GET",
    // data,
    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};



export const deleteFeaturedStory = async (id) => {
  const response = await axiosInstance.request({
    url: `deletefeaturestory/${id}`,
    method: "DELETE",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const deletejobList = async (id) => {
  const response = await axiosInstance.request({
    url: `deletejobList/${id}`,
    method: "DELETE",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const editFeaturedStoryApi = async (id) => {
  const response = await axiosInstance.request({
    url: `showfeaturestoryid/${id}`,
    method: "GET",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const updateFeaturedStory = async (featuredDetailData) => {
  const data = new FormData();
  data.append("heading", featuredDetailData.heading);
  data.append("discription", featuredDetailData.discription);
  data.append("image", featuredDetailData.image);

  data.append("id", featuredDetailData.id);

  const response = await axiosInstance.request({
    url: "updatefeatureStory",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const addhomedata = async (homedata) => {
 
  const data = new FormData();
  data.append("discription", homedata.description);
  data.append("stayimage", homedata.stayPic);
  data.append("diningimage", homedata.diningPic);
  data.append("travelimage", homedata.travelPic);
  data.append("diningdiscription", homedata.diningdescription);
  data.append("traveldiscription", homedata.traveldescription);
  data.append("staydiscription", homedata.staydescription);
  data.append("diningtitle", homedata.diningtitle);
  data.append("traveltitle", homedata.traveltitle);
  data.append("staytitle", homedata.staytitle);
  data.append("home_title", homedata.homeTitle);
  data.append("meta_title", homedata.metaTitle);
  data.append("meta_description", homedata.metaDescription);
  data.append("meta_tag", homedata.metaTag);


  const response = await axiosInstance.request({
    url: "addhome",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const updatehomedata = async (homedata) => {
 
  const data = new FormData();
  data.append("id", homedata.id);
  data.append("discription", homedata.description);
  data.append("stayimage", homedata.stayPic);
  data.append("diningimage", homedata.diningPic);
  data.append("travelimage", homedata.travelPic);
  data.append("diningdiscription", homedata.diningdescription);
  data.append("traveldiscription", homedata.traveldescription);
  data.append("staydiscription", homedata.staydescription);
  data.append("diningtitle", homedata.diningtitle);
  data.append("traveltitle", homedata.traveltitle);
  data.append("staytitle", homedata.staytitle);
  data.append("home_title", homedata.homeTitle);

  data.append("meta_title", homedata.metaTitle);
  data.append("meta_description", homedata.metaDescription);
  data.append("meta_tag", homedata.metaTag);

  const response = await axiosInstance.request({
    url: "updatehome",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const findhomeData = async (id) => {
  const response = await axiosInstance.request({
    url: `findhome/${id}`,
    method: "GET",

    header: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const showHomeContent = async () => {
   
  const response = await axiosInstance.request({
    url: "gethome",
    method: "GET",
    
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};