import React, { useEffect, useState } from "react";
import styles from "./../crms.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { addPrivacyNotice } from "../../../../api/adminPanel/privacyNoticeApi";

export default function EditContactUsSeo() {
  const navigate = useNavigate();
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTag, setMetaTag] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [description, setDescription] = useState("");

  const submitPrivacyNotice = async () => {
    const privacyNoticePayload = {
      discription: description,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
      metaTag: metaTag,
    };
    const response = await addPrivacyNotice(privacyNoticePayload);

    if (response.remote === "success") {
      navigate("/manage-privacy-notice");
    } else {
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Edit Contact Us Seo</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <label className="fw-bold">Enter Meta</label>
                <Col md={12}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Meta Title"
                    className={styles.form_input}
                    onChange={(e) => setMetaTitle(e.target.value)}
                  />
                </Col>
                <Col md={12}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Meta Description"
                    className={styles.form_input}
                    onChange={(e) => setMetaDescription(e.target.value)}
                  />
                </Col>
                <Col md={12}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Meta Tag"
                    className={styles.form_input}
                    onChange={(e) => setMetaTag(e.target.value)}
                  />
                </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={() => submitPrivacyNotice()}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
