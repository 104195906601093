import React, { useState, useEffect } from "react";
import styles from "./../job.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { addjobdescription } from "../../../../api/user";
import {
  editDescription,
  updatedescription,
} from "../../../../api/adminPanel/jobapi";
import swal from "sweetalert";
import ReactQuill from "react-quill";
export default function EditJobDescription() {
  const [description, setDescription] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("")
  const [metaTag, setMetaTag] = useState("")
  let { id } = useParams();
  const navigate = useNavigate();
  const handleGetDescriptionData = async (id) => {
    const response = await editDescription(id);

    if (response.remote === "success") {
      setDescription(response.data.data.discription);
      setMetaDescription(response.data.data.meta_description);
        setMetaTitle(response.data.data.meta_title);
        setMetaTag(response.data.data.meta_tag);
    } else {
    }
  };

  const handleUpdateDescription = async (id) => {
    const updateDescriptionPayload = {
      description: description,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
      metaTag: metaTag,
    };
    const response = await updatedescription(updateDescriptionPayload, id);

    if (response.remote === "success") {
      swal("Job Description Successfully Update!");
      navigate("/manage-job-description");
    } else {
    }
  };
  useEffect(() => {
    handleGetDescriptionData(id);
  }, []);
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Add Job page Description</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
              

                <Col md={12}>
                  {" "}
      
                  <ReactQuill
                    // theme="snow"
                    value={description}
                    placeholder="Description"
                    className={styles.form_input}
                    onChange={setDescription}
                  />
                </Col>
                <label className="fw-bold">Enter Meta</label>
      <Col md={12}>
        {" "}
        <Form.Control
          type="text"
          placeholder="Meta Title"
          className={styles.form_input}
          value={metaTitle}
          onChange={(e) => setMetaTitle(e.target.value)}
        />
      </Col>
      <Col md={12}>
        {" "}
        <Form.Control
          type="text"
          placeholder="Meta Description"
          className={styles.form_input}
          value={metaDescription}
          onChange={(e) => setMetaDescription(e.target.value)}
        />
      </Col>
      <Col md={12}>
        {" "}
        <Form.Control
          type="text"
          placeholder="Meta Tag"
          className={styles.form_input}
          value={metaTag}
          onChange={(e) => setMetaTag(e.target.value)}
        />
      </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={() => {
                      handleUpdateDescription(id);
                    }}
                  >
                    update
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
