import React, { useState } from "react";
import styles from "./../crms.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import { Divider } from "@mui/material";
import { deleteIcon } from "../../../../utils/svg.file";
import {
  addContactDetail,
  editShowContact,
  updateContactDetail,
} from "../../../../api/adminPanel/contactUs";
import swal from "sweetalert";
import { useEffect } from "react";
export default function EditContactUs() {
  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [firstMob, setFirstMob] = useState("");
  const [secondMob, setSecondMob] = useState("");
  const [thirdMob, setThirdMob] = useState("");
  const [email, setEmail] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [firstMobWhatsapp, setFirstMobWhatsapp] = useState(false);
  const [secondMobWhatsapp, setSecondMobWhatsapp] = useState(false);

  const [thirdMobWhatsapp, setThirdMobWhatsapp] = useState(false);

  let { id } = useParams();
  const handleGetContactData = async (id) => {
    const response = await editShowContact(id);

    if (response.remote === "success") {
      setCategory(response.data.data.category);
      setAddress(response.data.data.address);
      setLocation(response.data.data.location);
      setFirstMob(response.data.data.firstmobile);
      setSecondMob(response.data.data.secondmobile);
      setThirdMob(response.data.data.thirdmobile);
      setFirstMobWhatsapp(response.data.data.f_status);
      setSecondMobWhatsapp(response.data.data.s_status);
      setThirdMobWhatsapp(response.data.data.t_status);
      setEmail(response.data.data.other);
      setOrderNumber(response.data.data.order);

      //   setContactDetail(response.data.data);
    } else {
    }
  };

  const handleUpdateContactDetail = async (id) => {
    const updateContactDetailPayload = {
      location: location,
      address: address,
      category: category,
      firstMob: firstMob,
      secondMob: secondMob,
      thirdMob: thirdMob,
      email: email,
      f_status: firstMobWhatsapp,
      s_status: secondMobWhatsapp,
      t_status: thirdMobWhatsapp,
      orderNumber: orderNumber,
    };
    const response = await updateContactDetail(updateContactDetailPayload, id);

    if (response.remote === "success") {
      swal("Contact Detail Successfully Update!");
      navigate("/manage-contact-us");
    } else {
    }
  };

  useEffect(() => {
    handleGetContactData(id);
  }, []);
  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Edit Contact Us</h3>
              </div>
              <div className="">
                <Button
                  onClick={() => navigate(-1)}
                  className={styles.main_button}
                >
                  Back
                </Button>
              </div>
            </div>
            <Form>
              <div className="contact_form">
                <Row gutter={[24, 0]}>
                  <Col md={6}>
                    {" "}
                    <Form.Group className="">
                      <Form.Select
                        name="category"
                        className={styles.form_input}
                        defaultValue={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option
                          value="2"
                          selected={category === "2" ? true : false}
                        >
                          Hotel & Resorts
                        </option>
                        <option
                          value="3"
                          selected={category === "3" ? true : false}
                        >
                          Tamarind Restaurants
                        </option>
                        <option
                          value="1"
                          selected={category === "1" ? true : false}
                        >
                          Central Reservation
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    {" "}
                    <Form.Control
                      type="text"
                      name="location"
                      placeholder="Location"
                      className={styles.form_input}
                      defaultValue={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </Col>
                  <Col md={6}>
                    {" "}
                    <Form.Control
                      type="text"
                      name="address"
                      placeholder="Address"
                      className={styles.form_input}
                      defaultValue={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                    {" "}
                    <Form.Control
                      type="text"
                      name="firstMob"
                      placeholder="First Mobile Number"
                      className={styles.form_input}
                      defaultValue={firstMob}
                      onChange={(e) => setFirstMob(e.target.value)}
                    />
                  </Col>{" "}
                  <Col md={2}>
                    <div className="w-100 justify-content-end align-items-baseline d-flex">
                      <p
                        className="mb-0 me-2"
                        style={{
                          fontSize: "11px",
                          lineHeight: "10px",
                        }}
                      >
                        Whatsapp Enable
                      </p>
                      <input
                        type="checkbox"
                        name="firstMobWhatsapp"
                        placeholder=""
                        className={styles.check_input}
                        value={firstMobWhatsapp}
                        checked={
                          firstMobWhatsapp === "true" ||
                          firstMobWhatsapp === true
                            ? true
                            : false
                        }
                        onChange={(e) => setFirstMobWhatsapp(e.target.checked)}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <Form.Control
                      type="text"
                      name="secondMob"
                      placeholder="Second Mobile Number"
                      className={styles.form_input}
                      defaultValue={secondMob}
                      onChange={(e) => setSecondMob(e.target.value)}
                    />
                  </Col>{" "}
                  <Col md={2}>
                    <div className="w-100 justify-content-end align-items-baseline d-flex">
                      <p
                        className="mb-0 me-2"
                        style={{
                          fontSize: "11px",
                          lineHeight: "10px",
                        }}
                      >
                        Whatsapp Enable
                      </p>
                      <input
                        type="checkbox"
                        name="secondMobWhatsapp"
                        placeholder=""
                        className={styles.check_input}
                        value={secondMobWhatsapp}
                        checked={
                          secondMobWhatsapp === "true" ||
                          secondMobWhatsapp === true
                            ? true
                            : false
                        }
                        onChange={(e) => setSecondMobWhatsapp(e.target.checked)}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <Form.Control
                      type="text"
                      name="thirdMob"
                      placeholder="Third Mobile Number"
                      className={styles.form_input}
                      defaultValue={thirdMob}
                      onChange={(e) => setThirdMob(e.target.value)}
                    />
                  </Col>
                  <Col md={2}>
                    <div className="w-100 justify-content-end align-items-baseline d-flex">
                      <p
                        className="mb-0 me-2"
                        style={{
                          fontSize: "11px",
                          lineHeight: "10px",
                        }}
                      >
                        Whatsapp Enable
                      </p>
                      <input
                        type="checkbox"
                        name="thirdMobWhatsapp"
                        placeholder=""
                        className={styles.check_input}
                        defaultValue={thirdMobWhatsapp}
                        checked={
                          thirdMobWhatsapp === "true" ||
                          thirdMobWhatsapp === true
                            ? true
                            : false
                        }
                        onChange={(e) => setThirdMobWhatsapp(e.target.checked)}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    {" "}
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Email"
                      className={styles.form_input}
                      defaultValue={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Col>
                  <Col md={6}>
                    {" "}
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Order no."
                      className={styles.form_input}
                      defaultValue={orderNumber}
                      onChange={(e) => setOrderNumber(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="text-center  mt-5">
                    <Button
                      className={styles.main_button}
                      onClick={() => {
                        // navigate("/manage-contact-us");
                        handleUpdateContactDetail(id);
                      }}
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
